<app-navbar></app-navbar>
<div class="container mt-2">
  <div class="container animated fadeIn mt-5">
    <h1>Panelistas</h1>


    <h4>Seleccione un grupo de panelistas para ver editarlo</h4>
  </div>

  <hr class="mt-5">

  <div class="row mt-5 form-inline my-2 my-lg-0" *ngIf="!this.error">
    <div class="col text-right">
      <button routerLink="/panelistas/grupopanelistas/nuevo"
              class="btn btn-primary ">
        <i class="fa fa-plus">
        </i>
        Agregar Nuevo Grupo de Panelistas
      </button>
    </div>
  </div>

  <div class="container">
    <div class="card-columns mt-3 ">
      <div class="card" *ngFor="let panelistGroup of panelists">
        <div class="card-body">
          <h5 class="card-title">{{panelistGroup.name}}</h5>
          <p class="card-text">{{panelistGroup.description}}</p>
          <p class="card-text"><small class="text-muted">Este grupo posee {{panelistGroup.count}}
            usuario(s).</small></p>
          <a class="btn btn-outline-primary btn-block"
             [routerLink]="['/panelistas/grupopanelistas/', panelistGroup.id]">Editar / Modificar Usuarios</a>
          <a class="btn btn-outline-danger btn-block" (click)="deletePanelistGroup(panelistGroup)">Eliminar</a>
        </div>
      </div>
    </div>
  </div>

  <br>
  <nav *ngIf="finderActive === false" class="nav justify-content-center">
    <ul class="pagination justify-content-center">
      <li [ngClass]="{'disabled':pager.currentPage === 1}">
        <a class="page-link selectable" (click)="setPage(1)">Primero</a>
      </li>
      <li [ngClass]="{disabled: pager.currentPage === 1 }">
        <a class="page-link selectable" (click)="fetchPageResults(pager.currentPage - 1)">Anterior</a>
      </li>
      <li *ngFor="let page of pager.pages" [ngClass]="{'active': pager.currentPage === page}">
        <a class="page-link selectable" (click)="fetchPageResults(page)">
          {{page}}
        </a>
      </li>
      <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
        <a class="page-link selectable" (click)="fetchPageResults(pager.currentPage + 1)">Siguiente</a>
      </li>
      <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
        <a class="page-link selectable"(click)="setPage(pager.totalPages)">Último</a>
      </li>
    </ul>
  </nav>

  <div class="alert alert-info text-center mt-3 animated fadeIn fast" *ngIf="loading">
    <h4 class="alert-heading">Cargando...</h4>
    <p>
      <i class="fa fa-sync-alt fa-spin fa-2x"></i>
    </p>
    <p class="mb-0">
      Espere por favor
    </p>
  </div>

  <div class="alert alert-warning text-center mt-3 animated fadeIn fast" *ngIf="!loading && panelists.length == 0">
    <h4 class="alert-heading">No hay registros</h4>
    <p>
      <i class="fa fa-exclamation fa-2x"></i>
    </p>
  </div>
</div>
