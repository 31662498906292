<nav class="navbar navbar-expand-lg navbar-dark bg-dark animated fadeIn fast">
  <a class="navbar-brand">
    <img src="assets/img/logo.png" width="80" height="40" class="d-inline-block align-top" alt="Panel Sensorial">
    </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" routerLink="inicio">Inicio</a>
      </li>
      <li class="nav-item dropdown" routerLinkActive="active">
        <a class="nav-link dropdown-toggle" id="idmant" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Sesión
        </a>
        <div class="dropdown-menu" aria-labelledby="idmant">
          <a class="dropdown-item" routerLink="/sesiones/sesion/nuevo" *ngIf="this.loginService.getRole() != 'PN'">Crear sesión</a>
          <a class="dropdown-item" routerLink="/sesiones">Listar sesiones</a>
        </div>
      </li>
      <li class="nav-item dropdown" routerLinkActive="active" *ngIf="this.loginService.getRole() != 'PN'">
        <a class="nav-link dropdown-toggle" id="navbarPanelistDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Panelistas
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" routerLink="/panelistas">Grupos de Panelistas</a>
        </div>
      </li>
      <li class="nav-item dropdown" routerLinkActive="active" *ngIf="this.loginService.getRole() != 'PN'">
        <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Mantenedores
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" routerLink="/mantenedores/descriptores">Descriptores</a>
          <a class="dropdown-item" routerLink="/mantenedores/productos">Productos</a>
          <a class="dropdown-item" routerLink="/mantenedores/usuarios">Usuarios</a>
        </div>
      </li>
    </ul>
    <tr *ngFor="let country of this.loginService.countries">
      <img *ngIf="country === 'AR'" src="assets/icons/argentina_flag.png" height="15px" width="15px" style="margin-left: 5px;">
      <img *ngIf="country === 'CL'" src="assets/icons/chile_flag.png" height="15px" width="15px" style="margin-left: 5px;">
    </tr>

    <div class="nav-item dropdown" *ngIf="this.loginService.authenticated">
      <a href="#" data-toggle="dropdown" class="nav-item nav-link dropdown-toggle user-action" style="color: white">
        <img src="{{ this.loginService.getImage() }}" class="rounded-circle" alt="Avatar" width="35" height="35">
        Hola <b>{{ this.loginService.getName() }}</b>
      </a>
      <div class="dropdown-menu">
        <a href="#" class="dropdown-item" (click)="this.loginService.disconnect()"><i class="fa fa-sign-out"></i> Desconectar</a>
        <a class="dropdown-item" routerLink="/cambiar-password"><i class="fa fa-key"></i> Actualizar contraseña</a>
        <a class="nav-link disabled italic text-right"  href="#" tabindex="-1" aria-disabled="true">v.{{swVersion}}</a>
      </div>
    </div>
  </div>
</nav>

