import {Component, OnInit} from '@angular/core';
import {SessionModel} from '../../models/session.model';
import {SessionsService} from '../../services/sessions.service';
import Swal from 'sweetalert2';
import {PaginationModel} from '../../models/pagination.model';
import {UtilsService} from '../../services/utils.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LoginService} from '../../services/login.service';
import {Router} from "@angular/router";
import {environment} from '../../../environments/environment';
import {getPager} from 'ap-pagination';
import {FeatureModel} from "../../models/feature.model";

@Component({
  selector: 'app-sessions',
  templateUrl: './sessions.component.html',
  styleUrls: ['./sessions.component.scss']
})
export class SessionsComponent implements OnInit {
  offset = environment.PAGINATION_SIZE;
  limit = environment.PAGINATION_SIZE;

  allItems: any[];
  pager: any = {};
  pagedItems: any[];

  sessions: SessionModel[] = [];

  loading = false;
  error = false;
  finderActive = false;
  pagination: PaginationModel = new PaginationModel();
  sessionsBackUp: SessionModel[] = [];
  orderBy = '';
  fileData: File = null;
  loading_file = false;
  file: File = null;
  myOptions = this.utilService.getTooltipDefaultOptions();

  constructor(
    private sessionsService: SessionsService,
    private utilService: UtilsService,
    private modalService: NgbModal,
    public loginService: LoginService,
    private router: Router
    ) {}

  ngOnInit(): void {
    this.loading = true;
    this.sessionsService.getSessions(this.limit, 0).subscribe(response => {
      this.allItems = Array(response.pagination.total).fill(1);
      this.setPage(1);
      this.pagination.totalElements = response.pagination.total;
      this.pagination.nextPage = response.pagination.next_page;
      this.sessions = response.data;
      this.loading = false;
      this.sessionsBackUp = response.data;
      this.pagination = this.utilService.createPaginator(this.pagination);
    }, error => {
      this.loading = false;
      this.error = true;
      const status_code = error.status;
      const status_text = error.statusText;
      this.router.navigateByUrl('/login');
      Swal.fire({
        icon: 'warning',
        title: 'Obtener Sesiones',
        text: status_code + ' - ' + status_text
      });
    });
  }

  deleteSession(session: SessionModel): void {
    Swal.fire({
      icon: 'question',
      title: '¿Está seguro?',
      text: `Está seguro de que desea borrar la sessión: ${session.name}`,
      showConfirmButton: true,
      showCancelButton: true
    }).then(response => {
      if (response.value) {
        this.sessionsService.deleteSession(session.id)
          .subscribe((rs: any) => {
              if (rs.status === 200){
                Swal.fire({
                  icon: 'info',
                  title: 'Sessión eliminada correctamente',
                  text: rs.body.message
                });
                const index = this.sessions.indexOf(session);
                this.sessions.splice(index, 1);
              }
            },
            (errorResponse: any) => {
              if (errorResponse.status === 400){
                Swal.fire({
                  icon: 'error',
                  title: "No se puede eliminar",
                });
              }
            });
      }
    });
  }

  filterSession(searchTerm: string): void {
    if (searchTerm === undefined || searchTerm.trim() === ''){
      this.sessions = this.sessionsBackUp;
      this.finderActive = false;
      return;
    }
    this.loading = true;
    this.utilService.findByTerm(searchTerm, 'session')
      .subscribe((response) => {
        this.sessions = response.data;
        this.loading = false;
        this.finderActive = true;
      }, error => {
        this.loading = false;
        this.error = true;
        const status_code = error.status;
      const status_text = error.statusText;
        Swal.fire({
          icon: 'warning',
          title: 'Buscar Sesiones',
          text: status_code + ' - ' + status_text
        });
      });
  }

  cleanSearch(): void {
    this.sessions = this.sessionsBackUp;
    this.finderActive = false;
  }

  createPaginator(): void {
    // Todos los todos los elementos.
    if (this.pagination.nextPage) {
      // Calcular cantidad de elementos por página
      const limit = this.pagination.nextPage.match(/limit=(.*?)&/);
      const limitNumber = parseInt(limit[1]);
      this.pagination.pagesQuantity = Math.ceil(this.pagination.totalElements / limitNumber);

      const pagesArray = Array.from({length: this.pagination.pagesQuantity}, (_, i) => i + 1);
      // First Page
      pagesArray.splice(-1, 1);
      this.pagination.pages.push(`?limit=${limitNumber}`);
      pagesArray.forEach(element => {
        this.pagination.pages.push(`?limit=${limitNumber}&offset=${limitNumber * element}`);
      });
    } else {
      this.pagination.pagesQuantity = 1;
    }
  }

  fetchPageResults(pageQuery?: number): void {
    let new_offset = this.offset;
    let query: string;

    if (pageQuery !== 1) {
      new_offset = (this.offset * (pageQuery - 1));
    }else{
      new_offset = 0;
    }

    if (pageQuery <= this.pager.totalPages) {
      this.sessionsService.getSessions(this.limit, new_offset).subscribe(response => {
        this.sessions = response.data;
        this.pager.currentPage = pageQuery;
        this.pager = getPager(this.allItems.length, pageQuery, this.limit);
        this.pagedItems = this.allItems.slice(this.pager.startIndex, this.pager.endIndex + 1);
      });
    }
  }

  fetchOrderedSessions(order_by: string, isAscendingOrder: boolean): void {
    let new_offset = this.offset;
    order_by = isAscendingOrder ? '' + order_by : '-' + order_by;
    this.loading = true;
    this.sessions = [];
    this.pagination = new PaginationModel();
    this.sessionsService.getSessions(this.limit, new_offset).subscribe(response => {
      this.pagination.totalElements = response.pagination.total;
      this.orderBy = order_by;
      this.pagination.nextPage = response.pagination.next;
      this.sessions = response.data;
      this.loading = false;
      this.sessionsBackUp = response.data;
      this.pagination = this.utilService.createPaginator(this.pagination);
    }, error => {
      this.loading = false;
      this.error = true;
      const status_code = error.status;
      const status_text = error.statusText;
      Swal.fire({
        icon: 'warning',
        title: 'Obtener Sesiones',
        text: status_code + ' - ' + status_text
      });
    });
  }

  downloadSpreadsheet(session_id: string): void {
    const token = this.loginService.getToken();
    this.sessionsService.downloadSpreadsheet(session_id, token).subscribe(element => {
      const email = element.email;
      const source = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${element.file}`;
      const link = document.createElement('a');
      link.href = source;
      link.download = `session_${session_id}_${email}.xlsx`;
      link.click();
    });
  }

  onChange(event): void {
    this.file = event.target.files[0];
    this.loading_file = true;
  }

  onUpload(): void {
    if (this.loading_file === false) {
      Swal.fire({
        icon: 'warning',
        title: 'Carga Archivo',
        text: 'No ha seleccionado un archivo'
      });
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(this.file);
    reader.onload = (e: any) => {
      this.sessionsService.uploadSpreadsheet(reader.result).subscribe(
        response => {
          Swal.fire({
            icon: 'success',
            title: 'Carga completa',
            text: 'Sesión ' + response.session_id + ' - Usuario ' + response.email
          });
          this.modalService.dismissAll();
        });
    };
  }

  open(content): void {
    this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title'
    }).result.then((result) => {
    }, (reason) => {});
  }

  setPage(page): void {
    this.pager = getPager(this.allItems.length, page, 5);
    this.pagedItems = this.allItems.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }
}
