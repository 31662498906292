<div class="container mt-2">
  <div class="row text-right animated fadeIn fast">
    <div class="col">
      <button class="btn btn-danger" routerLink="/">
        <i class="fa fa-arrow-left"></i>
        Atrás
      </button>
    </div>
  </div>

  <div class="row text-right animated fadeIn fast">
    <tr>La nueva contraseña debe cumplir con los siguientes requisitos.</tr>
  </div>

  <div>
    <tr><td>- Las contraseñas deben coincidir</td></tr>
    <tr><td>- Debe tener al menos 8 caracteres</td></tr>
    <tr><td>- Debe tener al menos una letra minúscula</td></tr>
    <tr><td>- Debe tener al menos una letra mayúscula</td></tr>
    <tr><td>- Debe tener al menos un dígito</td></tr>
    <tr><td>- Debe tener al menos un símbolo</td></tr>
  </div>

  <div class="row animated fadeIn fast shadow p-4 mb-5">
    <div class="col">

        <div class="form-group">
          <label>Contraseña actual</label>
          <input type="password"
                 class="form-control"
                 placeholder="Contraseña actual"
                 name="current_password"
                 required
                 [(ngModel)]="this.passwordService.guest_model.current_password">
        </div>

        <div class="form-group">
          <label>Nueva contraseña</label>
          <input type="password"
                 class="form-control"
                 placeholder="Nueva contraseña"
                 name="new_password"
                 required
                 [(ngModel)]="this.passwordService.guest_model.new_password">
        </div>

      <div class="form-group">
          <label>Repetir nueva contraseña</label>
          <input type="password"
                 class="form-control"
                 placeholder="Repetir nueva contraseña"
                 name="repeat_new_password"
                 required
                 [(ngModel)]="this.passwordService.guest_model.repeat_new_password">
        </div>

      <div>
        <tr *ngFor="let message of this.passwordService.checkPassword()">
                <td>{{message}} &#10003;</td>
        </tr>
      </div>

        <div class="form-group text-center">
          <button type="submit"
                  class="btn btn-primary w-25"
                  (click)="this.passwordService.updateCredentials()">
            <i class="fa fa-save"></i>
            Guardar
          </button>
        </div>
    </div>
  </div>
</div>
