<app-navbar></app-navbar>
<div class="container mt-2">

  <h1 *ngIf="this.descriptor" class="animated fadeIn fast mt-5">Definición de Posiciones: <small>{{this.descriptor.name}}</small></h1>
  <hr>

  <div class="row text-right animated fadeIn fast">
    <div class="col">
      <button class="btn btn-danger" [routerLink]="['/mantenedores/descriptor/', this.descriptor.id]">
        <i class="fa fa-arrow-left"></i>
        Atrás
      </button>
    </div>
  </div>

  <br>

  <div class="row animated fadeIn fast shadow p-4 mb-5">
    <div class="col">
      <label style="font-size: 20px">Seleccione la posición para cada tipo de vino.</label>
      <br>
      <small>Este posicionamiento es utilizado en la evaluación de sesiones y las visualizaciones</small>
      <table class="table animated fadeIn fast mx-auto w-fixed mt-5">
        <thead class="thead-dark">
        <tr>
          <!--th scope="col">#</th-->
          <th scope="col">Posición</th>
          <th scope="col">Tipo de Vino</th>
          <th scope="col">Impacto</th>
          <th scope="col">Percepción</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let category of this.descriptor.descriptor_detail; index as i">
          <td *ngIf="category.selected === true">
            <div class="form-inline">
              <input type="number" class="form-control" min="1" max="100" *ngIf="category.feature_order === null" (input)="setFeaturePosition(100, i)" value=100/>
              <input type="number" class="form-control" min="1" max="100" *ngIf="category.feature_order !== null" (input)="setFeaturePosition($event.target.value, i)" value="{{category.feature_order}}"/>
            </div>
          </td>
          <td *ngIf="category.selected === true">
            <span class="badge badge-primary animated fadeIn fast mr-1 selectable"
                  *ngIf="category.wine_type_id === 'T'">Vino Tinto</span>
            <span class="badge badge-secondary animated fadeIn fast mr-1 selectable"
                  *ngIf="category.wine_type_id === 'R'">Vino Rosado</span>
            <span class="badge badge-success animated fadeIn fast mr-1 selectable"
                  *ngIf="category.wine_type_id === 'B'">Vino Blanco</span>
            <span class="badge badge-danger animated fadeIn fast mr-1 selectable"
                  *ngIf="category.wine_type_id === 'E'">Espumantes</span>
            <span class="badge badge-warning animated fadeIn fast mr-1 selectable"
                  *ngIf="category.wine_type_id === 'A'">Baya</span>
            <span class="badge badge-dark animated fadeIn fast mr-1 selectable"
                  *ngIf="category.wine_type_id === 'C'">Cooler</span>
          </td>
          <td *ngIf="category.selected === true">
            <div ngbDropdown class="d-inline-block">
              <button class="btn btn-outline-primary" ngbDropdownToggle *ngIf="category.impact === null">Sin asignar</button>
              <button class="btn btn-outline-primary" ngbDropdownToggle *ngIf="category.impact === 'N'">Nivel de Precio</button>
              <button class="btn btn-outline-primary" ngbDropdownToggle *ngIf="category.impact === 'D'">Defecto</button>
              <button class="btn btn-outline-primary" ngbDropdownToggle *ngIf="category.impact === 'E'">Estilo</button>
              <div ngbDropdownMenu>
                <button class="dropdown-item" (click)="setImpact(null, i)">Sin asignar</button>
                <button class="dropdown-item" (click)="setImpact('N', i)">Nivel de Precio</button>
                <button class="dropdown-item" (click)="setImpact('D', i)">Defecto</button>
                <button class="dropdown-item" (click)="setImpact('E', i)">Estilo</button>
              </div>
            </div>
          </td>
          <td *ngIf="category.selected === true">
            <div ngbDropdown class="d-inline-block">
              <button class="btn btn-outline-primary" ngbDropdownToggle *ngIf="category.perception === null">Sin asignar</button>
              <button class="btn btn-outline-primary" ngbDropdownToggle *ngIf="category.perception === 'B'">Boca</button>
              <button class="btn btn-outline-primary" ngbDropdownToggle *ngIf="category.perception === 'N'">Nariz</button>
              <div ngbDropdownMenu>
                <button class="dropdown-item" (click)="setPerception(null, i)">Sin asignar</button>
                <button class="dropdown-item" (click)="setPerception('B', i)">Boca</button>
                <button class="dropdown-item" (click)="setPerception('N', i)">Nariz</button>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>

      <br>
      <br>


      <div class="form-group text-center">
        <button type="submit" class="btn btn-primary w-25" (click)="saveFeature()">
          <i class="fa fa-save"></i>
          Guardar
        </button>
      </div>
    </div>
  </div>
</div>
