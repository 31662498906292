<app-navbar></app-navbar>
<div class="container mt-2">
<div class="container animated fadeIn mt-5">
  <h1>Evaluar Sesión: {{this.session_name}}</h1>

  <div class="text-right mt-5">
    <button class="btn btn-danger" routerLink="/sesiones">
      <i class="fa fa-arrow-left"></i>
      Volver
    </button>
  </div>
  <hr class="mt-5">
  <div class="multisteps-form__form">
    <div class="multisteps-form__progress">
      <button class="multisteps-form__progress-btn pointer" type="button" title="Evaluación de Muestras"
              id="step{{i}}"
              (click)="setMultiStep(product, i)" *ngFor="let product of this.products; index as i">Muestra {{i + 1}}
      </button>
    </div>
  </div>
  <div class="row animated fadeIn fast">
    <div class="table-responsive">
      <table class="table animated fadeIn fast mt-5">
        <thead class="thead-dark">
        <tr>
          <th scope="col">Descriptor</th>
          <th>{{this.product_header}}</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let feature of this.features; index as i">
          <th scope="row" class="animated fadeIn" id="feature{{feature.id}}">{{feature.name}}</th>
          <td id="product_detail">
            <div class="slider-container">
              <input type="range" min="0" max="5" id="feature{{i}}" step=".1"
                     style="width: 200px"
                     value="{{this.evaluation_scores[this.current_product.product_id + ',' + feature.id]}}"
                     (click)="this.printValue(this.current_product.product_id, feature, $event)"
                     (touchmove)="this.printValue(this.current_product.product_id, feature, $event)"
                     (keydown)="this.printValue(this.current_product.product_id, feature, $event)"
                     (touchend)="this.printValue(this.current_product.product_id, feature, $event)">
              <div style="vertical-align: auto">
                Puntaje: {{this.evaluation_scores[this.current_product.product_id + ',' + feature.id]}}
                <span *ngIf="this.show_statistics && this.calibration">(&nbsp;{{this.evaluation_mean[this.current_product.product_id + ',' + feature.id]}}
                &nbsp;+-&nbsp;
                  {{this.evaluation_std_dev[this.current_product.product_id + ',' + feature.id]}} )
                </span>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="form-group text-center">
    <button id="statistics-button" type="button" class="btn btn-primary w-25" *ngIf="this.show_calibration" (click)="this.getStatistics()">
      <i class="fa fa-bar-chart-o"></i>
      Estadísticas
    </button>
    &nbsp;
    <button id="save-button" type="submit" class="btn btn-primary w-25" (click)="this.saveEvaluation()">
      <i class="fa fa-save"></i>
      Guardar
    </button>
  </div>

  <div class="alert alert-info text-center mt-3 animated fadeIn fast" *ngIf="loading">
    <h4 class="alert-heading">Cargando...</h4>
    <p>
      <i class="fa fa-sync-alt fa-spin fa-2x"></i>
    </p>
    <p class="mb-0">
      Espere por favor
    </p>
  </div>

  <div class="alert alert-warning text-center mt-3 animated fadeIn fast"
       *ngIf="!loading && this.products.toString().length == 0">
    <h4 class="alert-heading">No hay registros</h4>
    <p>
      <i class="fa fa-exclamation fa-2x"></i>
    </p>
  </div>
</div>
</div>
