<!DOCTYPE html>
<html>
<head>
    <title>VSPT Winegroup - Panel Sensorial</title>
</head>
<body>
<section class="vh-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-4 text-black">

                <div align="center">
                    <img src="assets/img/img.png" width="300px" height="300px">
                </div>

                <div align="center">
                    <div class="h3">
                    <button style="border: 1px solid rgb(187, 187, 187); width: 180px;" type="button" class="btn btn-block btn-google" (click)="this.loginService.authAccount(true)">
                        <span className="google-icon-wrapper">
                            <img className="google-icon" src="https://d29fhpw069ctt2.cloudfront.net/icon/image/38622/preview.svg" style="height: 25px; width: 25px;" alt="google button" />
                            Cuenta Google
                        </span>
                    </button>
                    </div>
                    <hr>
                    <div id="logreg-forms">
                        <h1 class="h3 mb-3 font-weight-normal"
                            style="text-align: center; font-family: 'Verdana'">Invitados</h1>
                        <input autofocus="" class="form-control" id="inputEmail"
                               placeholder="Email address" required="" type="email"
                               style="width: 250px"
                               [(ngModel)]="this.loginService.guest_model.email"
                               >
                        <br>
                        <input class="form-control" id="inputPassword" placeholder="Password"
                               required="" type="password" style="width: 250px"
                               [(ngModel)]="this.loginService.guest_model.password">
                        <br>
                        <button class="btn btn-success btn-block" type="button"
                                style="width: 150px; background-color: burlywood; border-color: burlywood"
                                (click)="this.loginService.authGuest()">
                            <i class="fas fa-sign-in-alt"></i> Iniciar sesión
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 px-0 d-none d-sm-block">
                <img src="assets/img/image.png"
                     alt="Login image" class="w-150 vh-100"
                     style="object-fit: cover; object-position: left; width: 134%">
            </div>
        </div>
    </div>
</section>
</body>
</html>