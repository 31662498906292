import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { UserModel } from '../../../models/user.model';
import { UsersService } from 'src/app/services/users.service';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2';
import {Observable} from 'rxjs';
import {LoginService} from '../../../services/login.service';

@Component({
  selector: 'app-user-mant',
  templateUrl: './user-mant.component.html',
  styleUrls: ['./user-mant.component.scss']
})
export class UserMantComponent implements OnInit {

  user = new UserModel();
  is_visitor: boolean;
  message: string;
  role_type_disabled: boolean;

  constructor(private route: ActivatedRoute, public userService: UsersService, public loginService: LoginService) {
  }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
    const rt = this.route.snapshot.paramMap.get('rt');
    this.role_type_disabled = false;

    if (id !== 'nuevo') {
      this.userService.getUser(id, rt).subscribe(response => {
        this.user = response;
        if (rt === 'VT') {
          this.role_type_disabled = true;
          this.user.password = this.loginService.decryptPassword(this.user.password);
        }
        this.changed(this.user.role_type);
      });
    }
  }

  changed(role_type: string): void {
    if (role_type === 'VT'){
      this.is_visitor = true;
    }
    else{
      this.is_visitor = false;
    }
  }

  saveUser(form: NgForm): void {
    if (this.user.password !== undefined) {
      if ((this.user.password.length < 8) && (this.user.role_type === 'VT')) {
        this.message = '** Password debe contener al menos 8 caracteres';
      }
    }
    if (form.invalid){
      return;
    }
    Swal.fire({
      icon: 'info',
      title: 'Guardando información!',
      text: 'Espere...',
      allowOutsideClick: false
    });
    Swal.showLoading();

    let request: Observable<any>;

    if (this.user.id) {
      request = this.userService.updateUser(this.user, this.is_visitor);
    } else {
      request = this.userService.createUser(this.user, this.is_visitor);
    }

    request.subscribe(response => {
      Swal.fire({
        icon: 'success',
        title: 'Guardado correcto',
        text: this.user.name
      });
      this.message = null;
    }, error => {
      Swal.fire({
        icon: 'error',
        title: 'Ocurrió un problema al guardar los datos',
        text: this.user.name,
      });
    });
  }
}
