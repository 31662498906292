import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './components/home/home.component';
import {LoginComponent} from './components/login/login.component';
import {PasswordComponent} from './components/login/password.component';
import {PanelistsComponent} from './components/panelists/panelists.component';
import {SessionsComponent} from './components/sessions/sessions.component';
import {FeaturesComponent} from './components/maintainers/features-maintainer/features.component';
import {FeaturesOrderComponent} from './components/maintainers/feature-order-maintainer/feature-order-mant.component';
import {FeatureMantComponent} from './components/maintainers/feature-maintainer/feature-mant.component';
import {ProductsMantainerComponent} from './components/maintainers/products-maintainer/products-mantainer.component';
import {ProductMantainerComponent} from './components/maintainers/product-maintainer/product-mantainer.component';
import {SessionComponent} from './components/sessions/session/session.component';
import {UsersComponent} from './components/maintainers/users-maintainer/users.component';
import {UserMantComponent} from './components/maintainers/user-maintainer/user-mant.component';
import {PanelistgroupComponent} from './components/panelists/panelistgroup/panelistgroup.component';
import {EvaluateComponent} from './components/sessions/evaluate/evaluate.component';
import {LocalizationComponent} from './components/maintainers/localization-maintainer/localization.component';
import {ReportsComponent} from "./components/sessions/reports/reports.component";

const routes: Routes = [
  {path: 'inicio', component: HomeComponent},
  {path: 'login', component: LoginComponent},
  {path: 'cambiar-password', component: PasswordComponent},
  {path: 'mantenedores/:module/:id/localizacion', component: LocalizationComponent},
  {path: 'panelistas/:module/:id/localizacion', component: LocalizationComponent},
  {path: 'mantenedores/productos', component: ProductsMantainerComponent},
  {path: 'mantenedores/producto/:id', component: ProductMantainerComponent},
  {path: 'mantenedores/descriptores', component: FeaturesComponent},
  {path: 'mantenedores/descriptor/:id', component: FeatureMantComponent},
  {path: 'mantenedores/descriptor/:id/posiciones', component: FeaturesOrderComponent},
  {path: 'mantenedores/usuarios', component: UsersComponent},
  {path: 'mantenedores/usuario/:id', component: UserMantComponent},
  {path: 'mantenedores/usuario/:id/:rt', component: UserMantComponent},
  {path: 'panelistas', component: PanelistsComponent},
  {path: 'panelistas/grupopanelistas/:id', component: PanelistgroupComponent},
  {path: 'sesiones', component: SessionsComponent},
  {path: 'sesiones/sesion/:id', component: SessionComponent},
  {path: 'sesiones/evaluar/:id', component: EvaluateComponent},
  {path: 'sesiones/analisis/:id', component: ReportsComponent},
  {path: '**', pathMatch: 'full', redirectTo: 'inicio'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
