<app-navbar></app-navbar>
<div class="container mt-2">

  <h1 *ngIf="this.descriptor.id" class="animated fadeIn fast mt-5">Viendo Descriptor: <small>{{this.descriptor.name}}</small>
  </h1>
  <h1 *ngIf="!this.descriptor.id" class="animated fadeIn fast mt-5">Crear Nuevo Descriptor
    <small>{{this.descriptor.name}}</small>
  </h1>
  <hr>

  <div class="row text-right animated fadeIn fast">
    <div class="col">
      <button class="btn btn-danger" routerLink="/mantenedores/descriptores">
        <i class="fa fa-arrow-left"></i>
        Atrás
      </button>
    </div>
  </div>

  <div class="row animated fadeIn fast shadow p-4 mb-5">
    <div class="col">
      <form (ngSubmit)="saveFeature(form)" #form="ngForm">

        <div class="form-group">
          <label>ID</label>
          <input type="text"
                 class="form-control"
                 placeholder="ID del producto"
                 disabled="disabled"
                 [(ngModel)]="descriptor.id"
                 name="id">
          <small class="form-text text-muted">Este campo es autogenerado</small>
        </div>

        <div class="form-group">
          <label>Nombre</label>
          <input type="text"
                 class="form-control"
                 placeholder="Nombre del descriptor"
                 required
                 minlength="3"
                 [(ngModel)]="descriptor.name"
                 #name="ngModel"
                 [class.is-invalid]="name.invalid && name.touched"
                 [class.is-valid]="name.valid"
                 name="name">
          <small class="form-text text-danger"
                 *ngIf="name.invalid && name.touched">El campo nombre es obligatorio y con al menos 3 letras.</small>
        </div>

        <div class="form-group">
          <label>Descripción interna</label>
          <textarea class="form-control"
                    placeholder="Ingresar descripción de uso interno"
                    required
                    minlength="5"
                    [(ngModel)]="descriptor.description"
                    #description="ngModel"
                    [class.is-invalid]="description.invalid && description.touched"
                    [class.is-valid]="description.valid"
                    name="description">
        </textarea>
          <small class="form-text text-danger"
                 *ngIf="description.invalid && description.touched">El campo descripción es obligatorio y con al menos 5
            letras.</small>
        </div>

        <div class="form-group">
          <label *ngIf="!descriptor.id">Seleccione las categorías relacionados al nuevo descriptor</label>
          <label *ngIf="descriptor.id">Categorías al descriptor</label>

          <div class="row mb-2" *ngIf="descriptor.id">
            <small *ngIf="descriptor.descriptor_detail === 0">No hay categorías.</small>

            <div class="row" *ngFor="let productType of descriptor.descriptor_detail">
              <div class="col">
                <span class="badge badge-primary animated fadeIn fast mr-1 selectable"
                      *ngIf="productType.wine_type_id === 'T' && productType.selected === true">Vino Tinto</span>
                <span class="badge badge-secondary animated fadeIn fast mr-1 selectable"
                      *ngIf="productType.wine_type_id === 'R' && productType.selected === true">Vino Rosado</span>
                <span class="badge badge-success animated fadeIn fast mr-1 selectable"
                      *ngIf="productType.wine_type_id === 'B' && productType.selected === true">Vino Blanco</span>
                <span class="badge badge-danger animated fadeIn fast mr-1 selectable"
                      *ngIf="productType.wine_type_id === 'E' && productType.selected === true">Espumantes</span>
                <span class="badge badge-info animated fadeIn fast mr-1 selectable"
                      *ngIf="productType.wine_type_id === 'A' && productType.selected === true">Baya</span>
                <span class="badge badge-danger animated fadeIn fast mr-1 selectable"
                      *ngIf="productType.wine_type_id === 'C' && productType.selected === true">Cooler</span>
              </div>
            </div>
          </div>

          <div class="col" *ngIf="descriptor.id">
            <span>
              <button type="button" class="btn btn-outline-primary btn-sm mr-1" (click)="showDualList = !showDualList">Editar
              Tipos de Vino
            </button>
            </span>
            <span>
              <button type="button" class="btn btn-outline-primary btn-sm mr-1" [routerLink]="['/mantenedores/descriptor/' +this.descriptor.id+ '/posiciones']">
                Definir Posiciones
              </button>
            </span>
            <span *ngIf="this.descriptor.id">
              <button *ngIf="this.loginService.is_staff" type="button" class="btn btn-outline-primary btn-sm mr-1" [routerLink]="['/mantenedores/descriptor/' +this.descriptor.id+ '/localizacion']">
                Localización
              </button>
            </span>
          </div>

          <br>

          <dual-list *ngIf="!descriptor.id || showDualList"
                     [source]="wineTypes"
                     [(destination)]="selectedWineTypes"
                     [display]="listBoxDisplay"
                     [format]="listBoxFormat"
                     [key]="listBoxKey"
                     height="200px"
          ></dual-list>
        </div>

        <div class="form-group">
          <label>Fecha Creación</label>
          <input type="text"
                 class="form-control"
                 placeholder="Fecha de creación"
                 disabled="disabled"
                 [(ngModel)]="descriptor.created_dt"
                 name="created_dt">
          <small class="form-text text-muted">Este campo es autogenerado</small>
        </div>
        <div class="form-group">
          <label>Fecha Última Modificación</label>
          <input type="text"
                 class="form-control"
                 placeholder="Fecha de última modificación"
                 disabled="disabled"
                 [(ngModel)]="descriptor.updated_dt"
                 name="updated_dt">
          <small class="form-text text-muted">Este campo es autogenerado</small>
        </div>

        <hr>

        <div class="form-group text-center">
          <button type="submit"
                  class="btn btn-primary w-25">
            <i class="fa fa-save"></i>
            Guardar
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
