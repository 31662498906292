import {Component, OnInit} from '@angular/core';
import {PasswordService} from '../../services/password.service';
import {Router} from '@angular/router';
import {LoginService} from '../../services/login.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-change-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})

export class PasswordComponent implements OnInit {

  constructor(
    private router: Router,
    public passwordService: PasswordService,
    public loginService: LoginService
  ){}

  public message: string;

  ngOnInit(): void {
    const token = this.loginService.getToken();
    if (!token) {
      this.router.navigateByUrl('/login');
      Swal.fire({
        icon: 'warning',
        title: 'No Permitido',
        text: 'Debe autenticarse para cambiar su contraseña'
      });
    }
  }
}
