import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {PanelistGroupModel} from '../models/panelist.group.model';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PanelistsService {

  private URL_BASE = `${environment.API_URL}/api/panelist_group`;
  private LIMIT = 5;

  constructor(private http: HttpClient) {
  }


  createPanelistGroup(panelist: PanelistGroupModel): Observable<any> {
    return this.http.post(`${this.URL_BASE}`, panelist)
      .pipe(
        map((response: any) => {
            panelist.id = response.id;
            panelist.created_dt = response.created_dt;
            panelist.updated_dt = response.updated_dt;
            return panelist;
          }
        )
      );
  }

  updatePanelistGroup(panelist: PanelistGroupModel): Observable<any> {
    return this.http.put(`${this.URL_BASE}/${panelist.id}`, panelist);
  }


  getPanelistGroups(limit?: number, offset?: number): Observable<any> {
    const params = new HttpParams()
    .set('limit', String(limit))
    .set('offset', String(offset));
    return this.http.get(this.URL_BASE, {params});
  }


  deletePanelistGroup(id: string): Observable<any> {
    return this.http.delete(`${this.URL_BASE}/${id}`);
  }

  getPanelistGroupDetails(id: string): Observable<any> {
    return this.http.get(`${this.URL_BASE}/${id}`);
  }

  getAllPanelistsNames(): Observable<any> {
    return this.http.get(`${this.URL_BASE}/panelist_group/get_all_panelists/`);
  }
}
