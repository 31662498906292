import {Component, OnInit} from '@angular/core';
import {FeatureModel} from '../../../models/feature.model';
import Swal from 'sweetalert2';
import {PaginationModel} from '../../../models/pagination.model';
import {UtilsService} from '../../../services/utils.service';
import {FeaturesService} from '../../../services/features.service';
import {Router} from "@angular/router";
import {environment} from '../../../../environments/environment';
import {getPager} from 'ap-pagination';

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss']
})
export class FeaturesComponent implements OnInit {
  offset = environment.PAGINATION_SIZE;
  limit = environment.PAGINATION_SIZE;

  allItems: any[];
  pager: any = {};
  pagedItems: any[];

  features: FeatureModel[] = [];
  loading = false;
  finderActive = false;
  error = false;
  featuresBackUp: FeatureModel[] = [];
  pagination: PaginationModel = new PaginationModel();
  myOptions = this.utilService.getTooltipDefaultOptions();

  constructor(private featureService: FeaturesService,
              private utilService: UtilsService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.loading = true;
    this.featureService.getFeatures(this.limit, 0).subscribe(response => {
      this.allItems = Array(response.pagination.total).fill(1);
      this.setPage(1);
      this.pagination.totalElements = response.pagination.total;
      this.pagination.nextPage = response.pagination.next_page;
      this.features = response.data;
      this.loading = false;
      this.featuresBackUp = response.data;
      this.pagination = this.utilService.createPaginator(this.pagination);
    }, error => {
      this.loading = false;
      this.error = true;
      const status_code = error.status;
      const status_text = error.statusText;

      if (status_code === 401) {
        this.router.navigateByUrl('/login');
        Swal.fire({
          icon: 'warning',
          title: 'Obtener Descriptores',
          text: status_code + ' - ' + status_text
        });
      } else if (status_code === 403) {
        this.router.navigateByUrl('/inicio');
        Swal.fire({
          icon: 'warning',
          title: 'Obtener Descriptores',
          text: error.error.message
        });
      }
    });
  }

  deleteDescriptor(feature: FeatureModel): void {
    Swal.fire({
      icon: 'question',
      title: '¿Está seguro?',
      text: `Está seguro de que desea borrar el descriptor: ${feature.name}`,
      showConfirmButton: true,
      showCancelButton: true
    }).then(response => {
      if (response.value) {
        this.featureService.deleteFeature(feature.id)
          .subscribe((rs: any) => {
              if (rs.status === 200){
                Swal.fire({
                  icon: 'info',
                  title: 'Descriptor eliminado correctamente',
                  text: rs.body.message
                });
                const index = this.features.indexOf(feature);
                this.features.splice(index, 1);
              }
            },
            (errorResponse: any) => {
              if (errorResponse.status === 400){
                Swal.fire({
                  icon: 'error',
                  title: "No se puede eliminar",
                });
              }
            });
      }
    });
  }

  fetchPageResults(pageQuery?: number): void {
    let new_offset = this.offset;
    let query: string;

    if (pageQuery !== 1) {
      new_offset = (this.offset * (pageQuery - 1));
    }else{
      new_offset = 0;
    }

    if (pageQuery <= this.pager.totalPages) {
      this.featureService.getFeatures(this.limit, new_offset).subscribe(response => {
        this.features = response.data;
        this.pager.currentPage = pageQuery;
        this.pager = getPager(this.allItems.length, pageQuery, this.limit);
        this.pagedItems = this.allItems.slice(this.pager.startIndex, this.pager.endIndex + 1);
      });
    }
  }


  finderFeature(searchTerm: string): void {
    if (searchTerm === undefined || searchTerm.trim() === ''){
      this.features = this.featuresBackUp;
      this.finderActive = false;
      return;
    }
    this.loading = true;
    this.utilService.findByTerm(searchTerm, 'descriptor')
      .subscribe(response => {
        this.features = response.data;
        this.loading = false;
        this.finderActive = true;
      }, error => {
        this.loading = false;
        Swal.fire({
          icon: 'error',
          title: 'Buscar sesiones',
          text: 'Ocurrió un error al obtener los datos'
        });
      });
  }

  cleanSearch(): void {
    this.features = this.featuresBackUp;
    this.finderActive = false;
  }

  setPage(page): void {
    this.pager = getPager(this.allItems.length, page, 5);
    this.pagedItems = this.allItems.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }
}
