import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {UserModel} from '../models/user.model';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {SimpleUserModel} from '../models/simple.user.model';
import {LoginService} from "./login.service";

@Injectable({
  providedIn: 'root'
})

@Injectable()
export class UsersService {

  private URL_BASE = `${environment.API_URL}/api/user`;


  constructor(private http: HttpClient, public loginService: LoginService) {
  }

  createUser(user: UserModel, visitor: boolean): Observable<any> {
    return this.http.post(`${this.URL_BASE}/signup`, user)
      .pipe(
        map((response: any) => {
          user.id = response.id;
          return user;
        })
      );
  }

  updateUser(user: UserModel, visitor: boolean): Observable<any> {
    return this.http.put(`${this.URL_BASE}/${user.id}`, user);
  }

  getUsers(limit?: number, offset?: number): Observable<any> {
    const params = new HttpParams()
    .set('limit', String(limit))
    .set('offset', String(offset));
    return this.http.get(this.URL_BASE, {params});
  }

  getUser(id: string, rt: string): Observable<UserModel> {
    return this.http.get<UserModel>(`${this.URL_BASE}/${id}`);
  }

  deleteUser(id: string): Observable<HttpResponse<UserModel>> {
    return this.http.delete<UserModel>(`${this.URL_BASE}/${id}`, {observe: 'response'});
  }

  getSimpleUsersList(): Observable<any> {
    return this.http.get(this.URL_BASE);
  }

  searchUser(searchTerm: string, users: SimpleUserModel[]): SimpleUserModel[] {
    const usersArr: SimpleUserModel[] = [];
    searchTerm = searchTerm.toLowerCase();
    for (const user of users) {
      const name = user.name.toLowerCase();
      const email = user.email.toLowerCase();
      if (name.indexOf(searchTerm) >= 0 || user.id.toString().indexOf(searchTerm) >= 0 ||
          email.indexOf(searchTerm) >= 0
      ) {
        usersArr.push(user);
      }
    }
    return usersArr;
  }
}
