<app-navbar></app-navbar>
<div class="container mt-2">
  <div class="animated fadeIn mt-5">
    <h1>Listar Descriptores</h1>
  </div>

  <hr class="mt-5">

  <div class="row mt-5 form-inline my-2 my-lg-0" *ngIf="!this.error">
    <div class="col text-left">
      <input class="form-control mr-sm-2" type="search" placeholder="Buscar un descriptor" aria-label="Search"
             (keyup.enter)="finderFeature(findFeature.value)"
             #findFeature>
      <button class="btn btn-outline-success my-2 my-sm-0 mr-2" type="button" (click)="finderFeature(findFeature.value)">
        <i class="fa fa-search">
        </i>
        Buscar
      </button>
      <button class="btn btn-outline-warning my-2 my-sm-0" type="button" (click)="cleanSearch()">
        <i class="fa fa-search">
        </i>
        Limpiar
      </button>
    </div>

    <div class="col text-right">
      <button routerLink="/mantenedores/descriptor/nuevo"
              class="btn btn-primary ">
        <i class="fa fa-plus">
        </i>
        Agregar
      </button>
    </div>
  </div>

  <div class="table-responsive">
    <table class="table animated fadeIn fast mx-auto w-fixed mt-5"
           *ngIf="!loading && features.length != 0">
      <thead class="thead-dark">
      <tr>
        <th scope="col">ID</th>
        <th scope="col" style="width: 20%">Nombre</th>
        <th scope="col">Descripción</th>
        <th scope="col">Categoría</th>
        <th scope="col">Percepción</th>
        <th scope="col">Impacto</th>
        <th scope="col">Fecha Creación</th>
        <th scope="col">Acciones</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let feature of features;">
        <th scope="row" class="animated fadeIn">{{feature.id}}</th>
        <td class="animated fadeIn">{{feature.name}}</td>
        <td class="animated fadeIn">{{feature.description}}</td>
        <td class="animated fadeIn">
          <div class="row" *ngFor="let descriptor_detail of feature.descriptor_detail">
            <div class="col m-1" *ngIf="descriptor_detail.selected === true">
              <span class="badge badge-primary m-1" *ngIf="descriptor_detail.wine_type_id === 'T'">Vino Tinto</span>
              <span class="badge badge-secondary m-1" *ngIf="descriptor_detail.wine_type_id === 'R'">Vino Rosado</span>
              <span class="badge badge-success m-1" *ngIf="descriptor_detail.wine_type_id === 'B'">Vino Blanco</span>
              <span class="badge badge-danger m-1" *ngIf="descriptor_detail.wine_type_id === 'E'">Espumantes</span>
              <span class="badge badge-info m-1" *ngIf="descriptor_detail.wine_type_id === 'A'">Baya</span>
              <span class="badge badge-danger m-1" *ngIf="descriptor_detail.wine_type_id === 'C'">Cooler</span>
            </div>
          </div>
        </td>
        <td class="animated fadeIn">
          <div class="row" *ngFor="let descriptor_detail of feature.descriptor_detail">
            <div class="col m-1" *ngIf="descriptor_detail.selected === true">
              <span class="badge badge-secondary m-1" *ngIf="descriptor_detail.perception === 'N'">Nariz</span>
              <span class="badge badge-secondary m-1" *ngIf="descriptor_detail.perception === 'B'">Boca</span>
              <span class="badge badge-secondary m-1" *ngIf="descriptor_detail.perception === null">No aplica</span>
            </div>
          </div>
        </td>
        <td class="animated fadeIn">
          <div class="row" *ngFor="let descriptor_detail of feature.descriptor_detail">
            <div class="col m-1" *ngIf="descriptor_detail.selected === true">
              <span class="badge badge-secondary m-1" *ngIf="descriptor_detail.impact === 'N'">Nivel de precio</span>
              <span class="badge badge-secondary m-1" *ngIf="descriptor_detail.impact === 'D'">Defecto</span>
              <span class="badge badge-secondary m-1" *ngIf="descriptor_detail.impact === 'E'">Estilo</span>
              <span class="badge badge-secondary m-1" *ngIf="descriptor_detail.impact === null">No aplica</span>
            </div>
          </div>
        </td>

        <td class="animated fadeIn">{{feature.created_dt | date:'short'}}</td>
        <td class="animated fadeIn">
          <button class="btn btn-info mr-1" [routerLink]="['/mantenedores/descriptor/', feature.id]"  tooltip="Editar" [options]="myOptions">
            <i class="fa fa-pen"></i>
          </button>
          <button class="btn btn-danger mr-1" (click)="deleteDescriptor(feature)"  tooltip="Eliminar" [options]="myOptions">
            <i class="fa fa-trash"></i>
          </button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>


  <nav *ngIf="finderActive === false" class="nav justify-content-center">
    <ul class="pagination justify-content-center">
      <li [ngClass]="{'disabled':pager.currentPage === 1}">
        <a class="page-link selectable" (click)="setPage(1)">Primero</a>
      </li>
      <li [ngClass]="{disabled: pager.currentPage === 1 }">
        <a class="page-link selectable" (click)="fetchPageResults(pager.currentPage - 1)">Anterior</a>
      </li>
      <li *ngFor="let page of pager.pages" [ngClass]="{'active': pager.currentPage === page}">
        <a class="page-link selectable" (click)="fetchPageResults(page)">
          {{page}}
        </a>
      </li>
      <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
        <a class="page-link selectable" (click)="fetchPageResults(pager.currentPage + 1)">Siguiente</a>
      </li>
      <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
        <a class="page-link selectable"(click)="setPage(pager.totalPages)">Último</a>
      </li>
    </ul>
  </nav>

  <div class="alert alert-info text-center mt-3 animated fadeIn fast" *ngIf="loading">
    <h4 class="alert-heading">Cargando...</h4>
    <p>
      <i class="fa fa-sync-alt fa-spin fa-2x"></i>
    </p>
    <p class="mb-0">
      Espere por favor
    </p>
  </div>

  <div class="alert alert-warning text-center mt-3 animated fadeIn fast" *ngIf="!loading && features.length == 0">
    <h4 class="alert-heading">No hay registros</h4>
    <p>
      <i class="fa fa-exclamation fa-2x"></i>
    </p>
  </div>
</div>
