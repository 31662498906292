import {ActivatedRoute} from '@angular/router';
import {Component, OnInit} from '@angular/core';
import {FeatureModel} from '../../../models/feature.model';
import {FeaturesService} from 'src/app/services/features.service';
import Swal from 'sweetalert2';
import {Observable} from 'rxjs';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-feature-mant',
  templateUrl: './feature-order-mant.component.html',
  styleUrls: ['./feature-order-mant.component.scss']
})

export class FeaturesOrderComponent implements OnInit {
  descriptor = new FeatureModel();

  constructor(
    private featureService: FeaturesService, private modalService: NgbModal, private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');

    this.featureService.getFeature(id).subscribe(response => {
      this.descriptor = response;
    });
  }

  saveFeature(): void {
    Swal.fire({
      icon: 'info',
      title: 'Espere...',
      text: 'Guardando información!',
      allowOutsideClick: false
    });
    Swal.showLoading();

    let request: Observable<any>;

    request = this.featureService.updateFeature(this.descriptor);

    request.subscribe(response => {
      Swal.fire({
        icon: 'success',
        title: this.descriptor.name,
        text: 'Se guardó correctamente'
      });
    }, error => {
      Swal.fire({
        icon: 'error',
        title: this.descriptor.name,
        text: 'Ocurrió un error al guardar los datos'
      });
    });
  }

  setPerception(perception: string, index: number): void {
    this.descriptor['descriptor_detail'][index]['perception'] = perception;
  }

  setImpact(impact: string, index: number): void {
    this.descriptor['descriptor_detail'][index]['impact'] = impact;
  }

  setFeaturePosition(position, index): void {
    this.descriptor['descriptor_detail'][index]['feature_order'] = position;
  }
}
