export class UserModel {
  id: string;
  name: string;
  email: string;
  password: string;
  is_active: boolean;
  is_staff: boolean;
  role_type: string;
  account_type: string;
  created_dt: string;
  updated_dt: string;
}
