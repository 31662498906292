import {Component, OnInit, AfterViewChecked} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {EvaluateService} from 'src/app/services/evaluate.service';
import Swal from 'sweetalert2';
import {Observable} from 'rxjs';
import {LoginService} from '../../../services/login.service';

@Component({
  selector: 'app-components.sessions.evaluate',
  templateUrl: './evaluate.component.html',
  styleUrls: ['./evaluate.component.scss']
})
export class EvaluateComponent implements OnInit, AfterViewChecked {
  session_name: string;
  features: string [];
  products: string [] = [];
  evaluation_scores = {};
  evaluation_std_dev = {};
  evaluation_mean = {};
  loading = false;
  evaluations = [];
  current_product: any;
  product_header: string;
  show_statistics: boolean;
  calibration: string;
  show_calibration: boolean;

  constructor(
    private route: ActivatedRoute,
    public evaluateService: EvaluateService,
    public loginService: LoginService
    ) {}

  id = this.route.snapshot.paramMap.get('id');


  ngOnInit(): void {
    this.show_calibration = false;
    this.show_statistics = false;
    this.loading = true;
    const token = this.loginService.getToken();

    if (token !== null) {
      this.evaluateService.getEvalSession(this.id, token).subscribe(response => {
        this.loading = false;
        this.calibration = response.session_calibration_product;
        this.session_name = response.name;
        this.features = response.session_features;

        if (!response.session_products) {
          document.getElementById('save-button').style.display = 'none';
          document.getElementById('statistics-button').style.display = 'none';
        } else {
          this.products = response.session_products;
          /*response.session_products.forEach(element => {
            const product = response.session_products.find(x => x.id === element.product_id);
            this.products.push(product);
            this.current_product = this.products[0];
          });*/

          this.current_product = this.products[0];

          this.setCalibration(this.current_product.product_id);
          this.product_header = 'Muestra 1';
        }

        this.fillMatrix();
        if (response.evaluations && response.evaluations.length > 0) {
          response.evaluations.forEach(element => {
            if (element.score === -0.1) {
              this.evaluation_scores[element.product_id + ',' + element.feature_id] = 'NR';
            } else {
              this.evaluation_scores[element.product_id + ',' + element.feature_id] = element.score;
            }
          });
        } else {
          this.fillMatrix();
        }
      }, error => {
        this.loading = false;
        document.getElementById('save-button').style.display = 'none';
        const status_code = error.status;
        const status_text = error.statusText;
        Swal.fire({
          icon: 'warning',
          title: 'Obtener datos sesión',
          text: status_code + ' - ' + status_text
        });
      });
    } else {
      this.loading = false;
      Swal.fire({
        icon: 'error',
        title: 'Credenciales'
      });
    }
  }

  ngAfterViewChecked(): void {
    if (document.getElementById('step0') != null) {
      document.getElementById('step0').classList.add('js-active');
    }
  }

  fillMatrix(): void {
    if (this.features && this.products){
      this.features.forEach(feature => {
        this.products.forEach(product => {
          this.evaluation_scores[product['product_id'] + ',' + feature['id']] = 'NR';
        });
      });
    }
  }


  printValue(product, feature, selected_value): void {
    this.evaluation_scores[product + ',' + feature.id] = selected_value.target.value;
  }

  saveEvaluation(): void {
    Swal.fire({
      icon: 'info',
      title: 'Guardando Datos',
      text: this.session_name,
      allowOutsideClick: false
    });
    Swal.showLoading();

    let request: Observable<any>;
    const token = this.loginService.getToken();
    if (token) {
      this.evaluations = this.translateEvaluation('NR', -0.1);
      request = this.evaluateService.registerEval(this.id, this.evaluations, token);
    }

    request.subscribe(response => {
      this.evaluations = this.translateEvaluation(-0.1, 'NR');
      Swal.fire({
        icon: 'success',
        title: 'Guardado correcto',
        text: this.session_name
      });
    }, error => {
      Swal.fire({
        icon: 'error',
        title: this.session_name,
        text: error.error.message,
      });
    });
  }

  translateEvaluation(old_value: any, new_value: any): string[] {
    const trans_values = [];
    for (const value in this.evaluation_scores) {
      const product_id = value.split(',')[0];
      const feature_id = value.split(',')[1];
      if (this.evaluation_scores[value] === old_value) {
        this.evaluation_scores[value] = new_value;
      }
      const score = this.evaluation_scores[value];
      trans_values.push({
        product_id: product_id,
        feature_id: feature_id,
        score: score,
        unit: 'NA'
      });
    }
    return trans_values;
  }

  getStatistics(): void {
    Swal.fire({
      icon: 'info',
      title: 'Recuperando Datos',
      text: this.session_name,
      allowOutsideClick: false
    });
    Swal.showLoading();

    let request: Observable<any>;
    const token = this.loginService.getToken();
    if (token) {
      request = this.evaluateService.getStatistics(this.id, token);
    }

    request.subscribe(response => {
      let message = "Estadísticas Obtenidas";

      if (response.data.length === 0) {
        message = response.message;
      }

      response.data.forEach(element => {
        this.evaluation_std_dev[element.product_id + ',' + element.feature_id] = element.scores.std_dev;
        this.evaluation_mean[element.product_id + ',' + element.feature_id] = element.scores.average_score;
        this.show_statistics = true;
      });
      Swal.fire({
        icon: 'info',
        title: message,
        text: this.session_name
      });
    }, error => {
      Swal.fire({
        icon: 'error',
        title: this.session_name,
        text: 'Ocurrió un error al obtener las estadísticas',
      });
    });
  }

  setMultiStep(product: any, index: number): void {
    const status = this.checkEvaluationStep();

    if (status) {
      this.paintProgressBar(index);
      this.current_product = product;
      this.product_header = 'Muestra ' + String(index + 1);
      this.setCalibration(this.current_product.product_id);
    }
  }

  paintProgressBar(id: number): void {
    for (let i = id + 1; i <= this.products.length; i++) {
      if (document.getElementById(`step${i}`) != null) {
        document.getElementById(`step${i}`).classList.remove('js-active');
      }
    }
    for (let i = 0; i <= id; i++) {
      if (document.getElementById(`step${i}`) != null) {
        document.getElementById(`step${i}`).classList.add('js-active');
      }
    }
  }

  checkEvaluationStep(): boolean {
    let status = true;
    this.features.forEach(feature => {
      const score = this.evaluation_scores[this.current_product.product_id + ',' + feature['id']];
      if (score === 'NR') {
        Swal.fire({
          icon: 'warning',
          title: 'Evaluaciones Pendientes',
          text: 'Descriptor - ' + feature['name'],
          allowOutsideClick: false
        });
        status = false;
      }
    });
    return status;
  }

  setCalibration(product_id: string): void {
    if (product_id === this.calibration) {
      this.show_calibration = true;
      this.show_statistics = true;
    } else {
      this.show_calibration = false;
      this.show_statistics = false;
    }
  }
}
