<app-navbar></app-navbar>
<div class="container mt-2">
  <h1 *ngIf="this.panelist.id" class="animated fadeIn fast mt-5">Viendo Grupo de Panelista
    :<small>{{this.panelist.name}}</small></h1>
  <h1 *ngIf="!this.panelist.id" class="animated fadeIn fast mt-5">Crear Nuevo Grupo de Panelistas
    <small>{{this.panelist.name}}</small></h1>
  <hr>

  <div class="row text-right animated fadeIn fast">
    <div class="col">
      <button class="btn btn-danger" routerLink="/panelistas">
        <i class="fa fa-arrow-left"></i>
        Atrás
      </button>
    </div>
  </div>

  <div class="row animated shadow p-4 fadeIn fast mb-5">
    <div class="col">
      <form (ngSubmit)="savePanelistGroup(form)" #form="ngForm">

        <div class="form-group">
          <label>ID</label>
          <input type="text"
                 class="form-control"
                 placeholder="ID del grupo de panelistas"
                 disabled="disabled"
                 [(ngModel)]="panelist.id"
                 name="id">
          <small class="form-text text-muted">Este campo es autogenerado</small>
        </div>

        <div class="form-group">
          <label>Nombre</label>
          <input type="text"
                 class="form-control"
                 placeholder="Nombre del grupo de panelistas"
                 required
                 minlength="3"
                 [(ngModel)]="panelist.name"
                 #name="ngModel"
                 [class.is-invalid]="name.invalid && name.touched"
                 [class.is-valid]="name.valid"
                 name="name">
          <small class="form-text text-danger"
                 *ngIf="name.invalid && name.touched">El campo nombre es obligatorio y con al menos 3 letras.</small>
        </div>

        <div class="form-group">
          <label>Descripción interna</label>
          <textarea class="form-control"
                    placeholder="Ingresar descripción de uso interno"
                    required
                    minlength="5"
                    [(ngModel)]="panelist.description"
                    #description="ngModel"
                    [class.is-invalid]="description.invalid && description.touched"
                    [class.is-valid]="description.valid"
                    name="description">
        </textarea>
          <small class="form-text text-danger"
                 *ngIf="description.invalid && description.touched">El campo descripción es obligatorio y con al menos 5
            letras.</small>
        </div>

        <div class="form-group">
          <label>Fecha Creación</label>
          <input type="text"
                 class="form-control"
                 placeholder="Fecha de creación"
                 disabled="disabled"
                 [(ngModel)]="panelist.created_dt"
                 name="created_dt">
          <small class="form-text text-muted">Este campo es autogenerado</small>
        </div>
        <div class="form-group">
          <label>Fecha Última Modificación</label>
          <input type="text"
                 class="form-control"
                 placeholder="Fecha de última modificación"
                 disabled="disabled"
                 [(ngModel)]="panelist.updated_dt"
                 name="updated_dt">
          <small class="form-text text-muted">Este campo es autogenerado</small>
        </div>

        <hr *ngIf="this.panelist.id">
        <div class="form-group" *ngIf="this.panelist.id">
            <button *ngIf="this.loginService.is_staff" type="button" class="btn btn-outline-primary btn-sm mr-1" [routerLink]="['/panelistas/grupopanelistas/' +this.panelist.id+ '/localizacion']">
              Localización
            </button>
        </div>
        <hr>
        <div class="form-group swing">
          <h3 *ngIf="!panelist.id">Seleccione los Usuarios del grupo de panelistas</h3>
          <h3 *ngIf="panelist.id">Usuarios pertenecientes al grupo de panelistas</h3>
          <hr>
          <input class="form-control mr-sm-2 mb-2" type="search" placeholder="Filtrar Usuarios" aria-label="Search"
                 (keyup)="filterUsers(usersSearch.value)" #usersSearch>
          <dual-list
            [source]="users"
            [(destination)]="selectedUsers"
            [display]="listBoxDisplay"
            [format]="listBoxFormat"
            [key]="listBoxKey"
            height="200px"
          ></dual-list>
        </div>
        <div class="form-group text-center">
          <button type="submit"
                  class="btn btn-primary w-25">
            <i class="fa fa-save"></i>
            Guardar
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
