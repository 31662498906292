import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {GuestModel} from '../models/guest_login.model';
import * as CryptoJS from 'crypto-js';
import {EncryptStorage} from 'encrypt-storage';
import {LoginModel} from '../models/login.model';
import Swal from "sweetalert2";
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private baseUrl = `${environment.API_URL}/api/user`;
  public message: string;
  public authenticated = false;
  public is_staff = false;
  public account_email;
  public account_name;
  public account_image;
  public account_type;
  public countries: any[] = [];
  private account_role;
  public gapiSetup = false; // marks if the gapi library has been loaded
  public authInstance: gapi.auth2.GoogleAuth;
  public googleUser: gapi.auth2.GoogleUser;
  private tokenId: string;
  public guest_model = new GuestModel();
  private login_model = new LoginModel();
  private encrypt_storage = new EncryptStorage(environment.LS_ENCRYPTION);

  constructor(private http: HttpClient, private router: Router) {
    this.login_model = new LoginModel();
  }

  encryptPassword(password: string): string {
    const guest_password = CryptoJS.AES.encrypt(password.trim(), environment.GUEST_ME).toString();
    return guest_password;
  }

  decryptPassword(password: string): string {
    const decrypted = CryptoJS.AES.decrypt(password, environment.GUEST_ME).toString(CryptoJS.enc.Utf8);
    return decrypted;
  }

  authGuest(): void {
    const guest_email = this.guest_model.email;
    const guest_password = this.guest_model.password;
    const guest_data = this.http.post<any>(this.baseUrl + '/login',
      {
        email: guest_email,
        password: guest_password
      });
    guest_data.subscribe(data => {
      if (data){
        this.message = null;
        this.printData(data["data"]);
      } else {
        this.guest_model.email = null;
        this.guest_model.password = null;
        localStorage.removeItem('login');
        Swal.fire({
            icon: 'warning',
            title: 'No Permitido',
            text: 'Credenciales incorrectas'
          });
      }
    }, error => {
      Swal.fire({
            icon: 'warning',
            title: 'No Permitido',
            text: error.error.message
          });
    });
  }

  authAccount(signType: boolean): void {
    this.checkIfUserAuthenticated(signType)
      .then(log_status => {
        if (log_status) {
          this.tokenId = this.googleUser.getAuthResponse().id_token;
          // returns an observable object
          const account_data = this.http.post<any>(this.baseUrl + '/validate_token', {token: this.tokenId, source: 'p'});
          account_data.subscribe(data => {
            this.printData(data);
          });
        }
      }).catch(error => {
        localStorage.removeItem('login');
    });
  }

  printData(data): void {
    this.authenticated = true;
    if (data.account_type === 'GCP') {
      //this.setGCPImage(data.photo);
      this.setGCPImage('https://png.pngtree.com/png-vector/20190726/ourlarge/pngtree-grape-icon-png-image_1629609.jpg');
    } else {
      this.setGCPImage('https://png.pngtree.com/png-vector/20190726/ourlarge/pngtree-grape-icon-png-image_1629609.jpg');
    }
    this.countries = data.countries;
    this.setAccountRole(data.role_type);
    this.setGCPName(data.name);
    this.setGCPEmail(data.email);
    this.setAccountType(data.account_type);
    if (data.is_active || data.status === true) {
        this.setToken(data.token);
        this.is_staff = data.is_staff;

        if (this.router.url === "/login") {
          this.router.navigateByUrl('/inicio');
        }
      }
      else {
        this.message = 'Usuario ' + data.name + ' se encuentra Inactivo';
      }
    }

  setAccountType(account_type): void {
    this.account_type = account_type;
  }

  setGCPName(name): void {
    this.account_name = name;
  }

  setToken(token): void {
    this.login_model = new LoginModel();

    this.login_model.email = this.account_email;
    this.login_model.name = this.account_name;
    this.login_model.role_type = this.account_role;
    this.login_model.account_type = this.account_type;
    this.login_model.image = this.account_image;
    this.login_model.token = token;

    this.encrypt_storage.setItem('login', JSON.stringify(this.login_model));

  }

  setAccountRole(value): void {
    this.account_role = value;
  }

  setGCPEmail(email): void {
    this.account_email = email;
  }

  setGCPImage(image): void {
    this.account_image = image;
  }

  getToken(): string {
    this.login_model = this.encrypt_storage.getItem('login');

    if (this.login_model) {
      if (this.login_model.token) {
        this.authenticated = true;
      }
      return this.login_model.token;
    }

    return null;

  }

  getAccountType(): string {
    if (!this.account_type) {
      this.account_type = this.login_model.account_type;
    }
    if (this.account_type === 'GCP') {
      return 'Cuenta Google';
    } else {
      return 'Cuenta Visitante';
    }
  }

  getName(): string {
    if (!this.account_name) {
      this.account_name = this.login_model.name;
    }
    return this.account_name;
  }


  getImage(): string {
    if (!this.account_image) {
      this.account_image = this.login_model.image;
    }
    return this.account_image;
  }

  getEmail(): string {
    if (!this.account_email) {
      this.account_email = this.login_model.email;
    }
    return this.account_email;
  }

  getRole(): string {
    if (!this.account_role) {
      this.account_role = this.login_model.role_type;
    }
    return this.account_role;
  }

  signIn(): Promise<gapi.auth2.GoogleUser> {
    return this.authInstance.signIn()
      .then(user => {
        return user;
      });
  }

  async disconnect(): Promise<any> {
    this.authenticated = false;
    this.gapiSetup = false;
    this.encrypt_storage.removeItem('login');
    return this.authInstance.signOut();
  }

  async checkIfUserAuthenticated(signType): Promise<boolean> {
    // Initialize gapi if not done yet
    if (!this.gapiSetup) {
      await this.initGoogleAuth();
    }
    if (!this.authInstance.isSignedIn.get() && signType) {
      await this.signIn().then(data => {
        this.googleUser = data;
      });
    }
    return this.authInstance.isSignedIn.get();
  }

  async initGoogleAuth(): Promise<void> {
    //  Create a new Promise where the resolve function is the callback
    // passed to gapi.load
    const pload = new Promise((resolve) => {
      gapi.load('auth2', resolve);
    });

    // When the first promise resolves, it means we have gapi loaded
    // and that we can call gapi.init
    return pload.then(async () => {
      await gapi.auth2
        .init({
          // we have to hide this!!
          client_id: environment.GCP_CLIENT_ID
        })
        .then(auth => {
          this.gapiSetup = true;
          this.authInstance = auth;
          this.googleUser = this.authInstance.currentUser.get();
        });
    });
  }
}
