<app-navbar></app-navbar>
<div class="container mt-2">
  <h1 *ngIf="this.product.id" class="animated fadeIn fast mt-5">Viendo Producto :<small>{{this.product.name}}</small></h1>
  <h1 *ngIf="!this.product.id" class="animated fadeIn fast mt-5">Crear Nuevo Producto <small>{{this.product.name}}</small>
  </h1>
  <hr>

  <div class="row text-right animated fadeIn fast">
    <div class="col">
      <button class="btn btn-danger" routerLink="/mantenedores/productos">
        <i class="fa fa-arrow-left"></i>
        Atrás
      </button>
    </div>
  </div>

  <div class="row animated fadeIn fast shadow p-4 mb-5">
    <div class="col">
      <form (ngSubmit)="saveProduct(form)" #form="ngForm">

        <div class="form-group">
          <label>ID</label>
          <input type="text"
                 class="form-control"
                 placeholder="ID del producto"
                 disabled="disabled"
                 [(ngModel)]="product.id"
                 name="id">
          <small class="form-text text-muted">Este campo es autogenerado</small>
        </div>

        <div class="form-group">
          <label>Nombre</label>
          <input type="text"
                 class="form-control"
                 placeholder="Nombre del producto"
                 required
                 minlength="3"
                 [(ngModel)]="product.name"
                 #name="ngModel"
                 [class.is-invalid]="name.invalid && name.touched"
                 [class.is-valid]="name.valid"
                 name="name">
          <small class="form-text text-danger"
                 *ngIf="name.invalid && name.touched">El campo nombre es obligatorio y con al menos 3 letras.</small>
        </div>

        <div class="form-group">
          <label>Producto</label>
          <select class="form-control"
                  #product_tp_id="ngModel"
                  name="product_tp_id"
                  [(ngModel)]="product.product_tp_id">
            <option value="SA">Sin Asignar</option>
            <option value="T">Vino Tinto</option>
            <option value="R">Vino Rosado</option>
            <option value="B">Vino Blanco</option>
            <option value="E">Espumantes</option>
            <option value="A">Bayas</option>
            <option value="C">Cooler</option>
          </select>
        </div>

        <div class="form-group">
          <label>Año de cosecha</label>
          <input type="number"
                 class="form-control"
                 placeholder="Año de cosecha"
                 [(ngModel)]="product.harvest_year"
                 #harvest_year="ngModel"
                 name="harvest_year">
        </div>

        <div class="form-group">
          <label>Cepa</label>
          <input type="text"
                 class="form-control"
                 placeholder="Cepa del producto"
                 [(ngModel)]="product.strain"
                 #strain="ngModel"
                 name="harvestyear">
        </div>

        <div class="form-group">
          <label>Descripción interna</label>
          <textarea class="form-control"
                    placeholder="Ingresar descripción de uso interno"
                    [(ngModel)]="product.description"
                    #description="ngModel"
                    name="description">
        </textarea>
        </div>

        <div class="form-group">
          <label>Tipo de vino (código interno)</label>
          <input type="text"
                 class="form-control"
                 placeholder="Tipo de vino"
                 [(ngModel)]="product.wine_internal_tp"
                 #wine_internal_tp="ngModel"
                 name="wine_internal_tp">
        </div>


        <div class="form-group">
          <label>Nivel</label>
          <select class="form-control"
                  #level="ngModel"
                  name="level"
                  [(ngModel)]="product.level">
            <option value="SA">Sin Asignar</option>
            <option value="O">Nivel O</option>
            <option value="N">Nivel N</option>
            <option value="L">Nivel L</option>
            <option value="K">Nivel K</option>
            <option value="G">Nivel G</option>
            <option value="F">Nivel F</option>
            <option value="D">Nivel D</option>
            <option value="C">Nivel C</option>
            <option value="B">Nivel B</option>
            <option value="A">Nivel A</option>
            <option value="2A">Nivel 2A</option>
            <option value="3A">Nivel 3A</option>
            <option value="4A">Nivel 4A</option>
          </select>
        </div>

        <div class="form-group">
          <label>Modalidad</label>
          <select class="form-control"
                  #modality="ngModel"
                  name="modality"
                  [(ngModel)]="product.modality">
            <option value="SA">Sin Asignar</option>
            <option value="Mezcla">Mezcla</option>
            <option value="Tratamiento">Tratamiento</option>
            <option value="Benchmark">Benchmark</option>
          </select>
        </div>

        <div class="form-group">
          <label>Detalle Modalidad</label>
          <input type="text"
                 class="form-control"
                 placeholder="Detalle modalidad"
                 [(ngModel)]="product.modality_detail"
                 #modality_detail="ngModel"
                 name="modality_detail">
        </div>

        <div class="form-group">
          <label>Fecha Creación</label>
          <input type="text"
                 class="form-control"
                 placeholder="Fecha de creación"
                 disabled="disabled"
                 [(ngModel)]="product.created_dt"
                 name="created_dt">
          <small class="form-text text-muted">Este campo es autogenerado</small>
        </div>
        <div class="form-group">
          <label>Fecha Última Modificación</label>
          <input type="text"
                 class="form-control"
                 placeholder="Fecha de última modificación"
                 disabled="disabled"
                 [(ngModel)]="product.updated_dt"
                 name="updated_dt">
          <small class="form-text text-muted">Este campo es autogenerado</small>
        </div>

        <hr *ngIf="this.product.id">
        <div class="form-group" *ngIf="this.product.id">
            <button *ngIf="this.loginService.is_staff" type="button" class="btn btn-outline-primary btn-sm mr-1" [routerLink]="['/mantenedores/producto/' +this.product.id+ '/localizacion']">
              Localización
            </button>
        </div>

        <hr>
        <div class="form-group text-center">
          <button type="submit"
                  class="btn btn-primary w-25">
            <i class="fa fa-save"></i>
            Guardar
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
