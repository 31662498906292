import {Component, OnInit, AfterViewChecked} from '@angular/core';
import html2canvas from 'html2canvas';
import {ActivatedRoute} from '@angular/router';
import Swal from 'sweetalert2';
import {LoginService} from '../../../services/login.service';
import {SessionsService} from "../../../services/sessions.service";

@Component({
  selector: 'app-components.sessions.reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit, AfterViewChecked {
  session_name: string;
  products: string [] = [];
  loading = false;
  payload = {};

  constructor(
    private route: ActivatedRoute,
    public sessionService: SessionsService,
    public loginService: LoginService
  ) {}

  id = this.route.snapshot.paramMap.get('id');


  ngOnInit(): void {
    this.loading = true;
    const token = this.loginService.getToken();

    if (token !== null) {
      this.sessionService.getSession(this.id, 'chemical').subscribe(response => {
        this.loading = false;
        this.session_name = response.name;
        this.products = response.chemical_analysis;
      }, error => {
        this.loading = false;
        document.getElementById('save-button').style.display = 'none';
        const status_code = error.status;
        const status_text = error.statusText;
        Swal.fire({
          icon: 'warning',
          title: 'Obtener datos sesión',
          text: status_code + ' - ' + status_text
        });
      });
    } else {
      this.loading = false;
      Swal.fire({
        icon: 'error',
        title: 'Credenciales'
      });
    }
  }

  ngAfterViewChecked(): void {
    if (document.getElementById('step0') != null) {
      document.getElementById('step0').classList.add('js-active');
    }
  }

  onInputChange(event: any) {
    const inputElement = event.target as HTMLElement;
    let input = inputElement.textContent || '';
    const numericRegex = /^\d*\.?\d*$/;

    if (!numericRegex.test(input) || input.length > 5 || (input.match(/\./g) || []).length > 1) {
      input = input.replace(/[^\d.]/g, '').substring(0, 5);
      inputElement.textContent = input;
    }
  }


  onInput(productId: number, columnName: string, score: number){
    const inputElement = event.target as HTMLElement;
    let input = inputElement.textContent || '';
    const numericRegex = /^\d*\.?\d*$/;

    if (!numericRegex.test(input) || input.length > 6 || (input.match(/\./g) || []).length > 1) {
      input = input.replace(/[^\d.]/g, '').substring(0, 6);
      inputElement.textContent = input;
    }

    this.payload[productId + ',' + columnName] = Number(score);
  }

  saveReport() {
    const payload = {
      'session_id': Number(this.id),
      'analysis': 'chemical',
      'data': this.payload
    }
    this.sessionService.setSessionAnalysis(payload);
  }

  exportTable() {
    const element = document.getElementById('table-container');
    html2canvas(element!).then(canvas => {
      const link = document.createElement('a');
      link.download = 'tables.png';
      link.href = canvas.toDataURL();
      link.click();
    });
  }
}
