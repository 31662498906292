import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {NgForm} from '@angular/forms';
import Swal from 'sweetalert2';
import {Observable} from 'rxjs';
import {ProductModel} from '../../../models/product.model';
import {ProductsService} from '../../../services/products.service';
import {LoginService} from 'src/app/services/login.service';

@Component({
  selector: 'app-product-mantainer',
  templateUrl: './product-mantainer.component.html',
  styleUrls: ['./product-mantainer.component.scss']
})
export class ProductMantainerComponent implements OnInit {
  product = new ProductModel();

  constructor(private productService: ProductsService,
              private route: ActivatedRoute,
              public loginService: LoginService) {
  }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');

    if (id !== 'nuevo') {
      this.productService.getProduct(id).subscribe(response => {
        this.product = response;
      });
    }
  }

  saveProduct(form: NgForm): void {
    if (form.invalid) {
      Object.values(form.controls).forEach(control => {
        control.markAsTouched();
      });
      return;
    }

    Swal.fire({
      icon: 'info',
      title: 'Espere...',
      text: 'Guardando información!',
      allowOutsideClick: false
    });
    Swal.showLoading();

    let request: Observable<any>;

    if (this.product.id) {
      request = this.productService.updateProduct(this.product);
    } else {
      request = this.productService.createProduct(this.product);
    }

    request.subscribe(response => {
      Swal.fire({
        icon: 'success',
        title: this.product.name,
        text: 'Se guardó correctamente'
      });
    }, error => {
      Swal.fire({
        icon: 'error',
        title: this.product.name,
        text: 'Ocurrió un error al guardar los datos'
      });
    });
  }
}
