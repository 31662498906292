import {Component, OnInit} from '@angular/core';
import {PanelistsService} from '../../services/panelists.service';
import {PanelistGroupModel} from '../../models/panelist.group.model';
import {PaginationModel} from '../../models/pagination.model';
import {UtilsService} from '../../services/utils.service';
import Swal from 'sweetalert2';
import {UsersService} from '../../services/users.service';
import {Router} from "@angular/router";
import {environment} from 'src/environments/environment';
import {getPager} from 'ap-pagination';

@Component({
  selector: 'app-panelists',
  templateUrl: './panelists.component.html',
  styleUrls: ['./panelists.component.scss']
})
export class PanelistsComponent implements OnInit {
  offset = environment.PAGINATION_SIZE;
  limit = environment.PAGINATION_SIZE;

  allItems: any[];
  pager: any = {};
  pagedItems: any[];

  panelists: PanelistGroupModel[];
  loading = false;
  error = false;
  pagination: PaginationModel = new PaginationModel();
  finderActive = false;

  constructor(private panelistService: PanelistsService,
              private utilService: UtilsService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.loading = true;
    this.panelistService.getPanelistGroups(this.limit, 0).subscribe(response => {
      this.allItems = Array(response.pagination.total).fill(1);
      this.setPage(1);
      this.pagination.totalElements = response.pagination.total;
      this.pagination.nextPage = response.pagination.next_page;
      this.panelists = response.data;
      this.loading = false;
      this.pagination = this.utilService.createPaginator(this.pagination);
    }, error => {
      this.loading = false;
      this.error = true;
      const status_code = error.status;
      const status_text = error.statusText;

      if (status_code === 401) {
        this.router.navigateByUrl('/inicio');
        Swal.fire({
          icon: 'warning',
          title: 'Obtener Panelistas',
          text: status_code + ' - ' + status_text
        });
      } else if (status_code === 403) {
        this.router.navigateByUrl('/inicio');
        Swal.fire({
          icon: 'warning',
          title: 'Obtener Panelistas',
          text: error.error.message
        });
      }
    });
  }

  fetchPageResults(pageQuery?: number): void {
    let new_offset = this.offset;
    let query: string;

    if (pageQuery !== 1) {
      new_offset = (this.offset * (pageQuery - 1));
    }else{
      new_offset = 0;
    }

    if (pageQuery <= this.pager.totalPages) {
      this.panelistService.getPanelistGroups(this.limit, new_offset).subscribe(response => {
        this.panelists = response.data;
        this.pager.currentPage = pageQuery;
        this.pager = getPager(this.allItems.length, pageQuery, this.limit);
        this.pagedItems = this.allItems.slice(this.pager.startIndex, this.pager.endIndex + 1);
      });
    }
  }


  deletePanelistGroup(panelist: PanelistGroupModel): void {

    Swal.fire({
      icon: 'question',
      title: '¿Está seguro?',
      text: `Está seguro de que desea borrar el grupo de panelistas: ${panelist.name}?`,
      showConfirmButton: true,
      showCancelButton: true
    }).then(response => {
      if (response.value) {
        const index = this.panelists.indexOf(panelist);
        this.panelistService.deletePanelistGroup(panelist.id)
          .subscribe(rs => {
            if (rs.message && rs.status !== 200){
              Swal.fire({
                icon: 'error',
                title: 'Eliminar grupo de panelistas',
                text: rs.message
              });
            }else{
              this.panelists.splice(index, 1);
            }
          });
      }
    });
  }

  setPage(page): void {
    this.pager = getPager(this.allItems.length, page, 5);
    this.pagedItems = this.allItems.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }
}
