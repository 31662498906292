<app-navbar></app-navbar>
<div class="container mt-2">
  <h1 *ngIf="this.user.id" class="animated fadeIn fast mt-5">Viendo Usuario: <small>{{this.user.name}}</small></h1>
  <h1 *ngIf="!this.user.id" class="animated fadeIn fast mt-5">Crear Nuevo Usuario <small>{{this.user.name}}</small></h1>
  <hr>
  <div class="row text-right animated fadeIn fast">
    <div class="col">
      <button class="btn btn-danger" routerLink="/mantenedores/usuarios">
        <i class="fa fa-arrow-left"></i>
        Atrás
      </button>
    </div>
  </div>
  <div class="row animated fadeIn fast shadow p-4 mb-5">
    <div class="col">
      <form (ngSubmit)="saveUser(form)" #form="ngForm">
        <div class="form-group">
          <label>ID</label>
          <input type="text" class="form-control" placeholder="ID del usuario" disabled="disabled" [(ngModel)]="user.id" name="id">
          <small class="form-text text-muted">Este campo es autogenerado</small>
        </div>
        <div class="form-group">
          <label>Nombre</label>
          <input type="text" class="form-control" placeholder="Nombre del usuario" required [(ngModel)]="user.name" name="name">
        </div>
        <div class="form-group">
          <label>Email</label>
          <input type="email" class="form-control" placeholder="Email del usuario" required [(ngModel)]="user.email" name="email">
        </div>
        <div class="form-group">
          <label>Estado</label>
          <select class="form-control"
                  required
                  #is_active="ngModel"
                  name="is_active"
                  [class.is-invalid]="is_active.invalid && is_active.touched"
                  [class.is-valid]="is_active.valid"
                  [(ngModel)]="user.is_active">
            <option value="" selected="">[Seleccionar un estado]</option>
            <option value="true">Activo</option>
            <option value="false">Inactivo</option>
          </select>
        </div>
        <div class="form-group">
          <div class="form-check">
            <input type="checkbox" class="form-check-input"
                   name="is_staff"
                   #is_staff="ngModel"
                   [(ngModel)]="user.is_staff">
            <label class="form-check-label">Administrador</label>
          </div>
        </div>
        <div class="form-group">
          <label>Rol de usuario</label>
          <select class="form-control"
                  required
                  [disabled]= "this.role_type_disabled"
                  #role_type="ngModel"
                  name="role_type"
                  [class.is-invalid]="role_type.invalid && role_type.touched"
                  [(ngModel)]="user.role_type"
                  (change)="changed($event.target.value)">
            <option value="" selected="">[Seleccionar un rol]</option>
            <option *ngIf="this.user.account_type === 'GCP'" value="MK">Marketing</option>
            <option *ngIf="this.user.account_type === 'GCP'" value="RS">Investigación</option>
            <option *ngIf="this.user.account_type === 'GCP'" value="QT">Calidad</option>
            <option *ngIf="this.user.account_type === 'GCP'" value="PN">Panelista</option>
            <option *ngIf="this.user.account_type !== 'GCP'" value="VT">Visitante</option>
            <option value="CR">Creado</option>
          </select>
          <small class="form-text text-danger"
                 *ngIf="role_type.invalid && role_type.touched">El rol de usuario es obligatorio.</small>
        </div>
        <div class="form-group" *ngIf="is_visitor">
          <label>Password</label>
          <input type="password" class="form-control" #password="ngModel" [(ngModel)]="user.password" name="password"
                 minlength="8">
        </div>
        <div class="form-group">
          <label>Fecha Creación</label>
          <input type="text" class="form-control" placeholder="Fecha Creación" [(ngModel)]="user.created_dt"
                 name="created_dt" disabled="disabled">
          <small class="form-text text-muted">Este campo es autogenerado</small>
        </div>
        <div class="form-group">
          <label>Última Actualización</label>
          <input type="text" class="form-control" placeholder="Última Actualización" required
                 [(ngModel)]="user.updated_dt" name="updated_dt" disabled="disabled">
          <small class="form-text text-muted">Este campo es autogenerado</small>
        </div>

        <hr *ngIf="this.user.id">
        <div class="form-group">
          <span *ngIf="this.user.id">
            <button *ngIf="this.loginService.is_staff" type="button" class="btn btn-outline-primary btn-sm mr-1" [routerLink]="['/mantenedores/usuario/' +this.user.id+ '/localizacion']">
              Localización
            </button>
          </span>
        </div>

        <hr>
        <p>{{this.message}}</p>
        <div class="form-group text-center">
          <button type="submit" class="btn btn-primary w-25">
            <i class="fa fa-save"></i>
            Guardar
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
