import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {ProductModel} from '../models/product.model';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {SimpleUserModel} from '../models/simple.user.model';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {
  private URL_BASE = `${environment.API_URL}/api/product`;
  private LIMIT = 5;
  constructor(private http: HttpClient) {
  }


  createProduct(product: ProductModel): Observable<any> {
    return this.http.post(`${this.URL_BASE}`, product)
      .pipe(
        map((response: any) => {
            product.id = response.id;
            product.created_dt = response.created_dt;
            product.updated_dt = response.updated_dt;
            return product;
          }
        )
      );
  }

  updateProduct(product: ProductModel): Observable<any> {
    return this.http.put(`${this.URL_BASE}/${product.id}`, product);
  }


  getProducts(limit?: number, offset?: number): Observable<any> {
    const params = new HttpParams()
    .set('limit', String(limit))
    .set('offset', String(offset));
    return this.http.get(this.URL_BASE, {params});
  }


  getProduct(id: string): Observable<any> {

    return this.http.get(`${this.URL_BASE}/${id}`);
  }

  deleteProduct(id: string): Observable<HttpResponse<any>> {
    return this.http.delete(`${this.URL_BASE}/${id}`, {observe: 'response'});
  }

  getAllProducts(wine_type?: string, country?: string): Observable<any> {
    let params = new HttpParams().set('limit', String(10000));

    if (wine_type !== '') {
      params = params.set('wine_type', String(wine_type));
    }

    if (country !== '') {
      params = params.set('country', String(country));
    }

    return this.http.get(this.URL_BASE, {params});
  }

  searchProduct(searchTerm: string, products: ProductModel[]): ProductModel[] {
    const productsArr: ProductModel[] = [];
    searchTerm = searchTerm.toLowerCase();
    for (const product of products) {
      const name = product.name.toLowerCase();
      if (name.indexOf(searchTerm) >= 0 || product.id.toString().indexOf(searchTerm) >= 0) {
        productsArr.push(product);
      }
    }
    return productsArr;
  }
}
