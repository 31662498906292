import {Component, OnInit} from '@angular/core';
import {LoginService} from '../../services/login.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  token: string;
  private data: any = {
    name: 'QUVO',
    company: 'QUVO'
  };
  

  constructor(private router: Router, public loginService: LoginService) {
  }

  ngOnInit(): void {
    this.token = this.loginService.getToken();
    if (this.token === null){
      this.router.navigateByUrl('/login');
    }
  }
}
