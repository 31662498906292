import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {UserModel} from '../models/user.model';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {SimpleUserModel} from '../models/simple.user.model';
import {LoginService} from "./login.service";

@Injectable({
  providedIn: 'root'
})

@Injectable()
export class LocalizationService {

  private URL_BASE = `${environment.API_URL}/api`;


  constructor(private http: HttpClient, public loginService: LoginService) {
  }

  getLocalization(identifier: number, module?: string): Observable<any> {
    return this.http.get(`${this.URL_BASE}/${module}/${identifier}/localization`);
  }

  saveLocalization(identifier: number, module?: string, selectedCountries?: any[]): Observable<any> {
    return this.http.put(`${this.URL_BASE}/${module}/${identifier}/localization`, {'countries': selectedCountries});
  }
}
