<app-navbar></app-navbar>
<div class="container mt-2">

  <h1 *ngIf="this.identifier" class="animated fadeIn fast mt-5">Definición de Países</h1>
  <hr>

  <div class="row text-right animated fadeIn fast">
    <div class="col">
      <button class="btn btn-danger" [routerLink]="[this.previousUrl]">
        <i class="fa fa-arrow-left"></i>
        Atrás
      </button>
    </div>
  </div>

  <br>

  <div class="row animated fadeIn fast shadow p-4 mb-5">
    <div class="col">
      <label style="font-size: 20px">Seleccione los países a los cuales estará relacionado el {{this.module}}.</label>
      <br>
      <dual-list [source]="countries"
                 [(destination)]="selectedCountries"
                 [display]="listBoxDisplay"
                 [format]="listBoxFormat"
                 [key]="listBoxKey"
                 height="200px"
      ></dual-list>

      <br>
      <br>

      <div class="form-group text-center">
        <button type="submit" class="btn btn-primary w-25" (click)="saveLocalization()">
          <i class="fa fa-save"></i>
          Guardar
        </button>
      </div>
    </div>
  </div>
</div>
