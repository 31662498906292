import {Injectable} from '@angular/core';
import {PaginationModel} from '../models/pagination.model';
import {environment} from '../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  private URL_BASE = `${environment.API_URL}/api`;

  constructor(private http: HttpClient) {
  }
  findByTerm(searchTerm: string, queryType: string): Observable<any> {
    const params = new HttpParams()
    .set('search_term', String(searchTerm));
    return this.http.get(`${this.URL_BASE}/${queryType}`, {params});

    /*return this.http.get(`${this.URL_BASE}/core/find/?query=${searchTerm}&type=${queryType}`)
      .pipe(
        map((response: any) => {
           return response;
          }
        )
      );
    */
  }

  createPaginator(paginator: PaginationModel): PaginationModel {
    if (paginator.nextPage) {
      const urlParams = new URLSearchParams(new URL(paginator.nextPage).search);
      const limitNumber = parseInt(urlParams.get("limit"));
      paginator.pagesQuantity = Math.ceil(paginator.totalElements / limitNumber);

      const pagesArray = Array.from({length: paginator.pagesQuantity}, (_, i) => i + 1);
      pagesArray.splice(-1, 1);
      paginator.pages.push(`?limit=${limitNumber}`);
      pagesArray.forEach(element => {
        paginator.pages.push(`?limit=${limitNumber}&offset=${limitNumber * element}`);
      });

    } else {
      paginator.pagesQuantity = 1;
    }

    return paginator;
  }

  getTooltipDefaultOptions(): any {
    return {
      placement: 'left',
      'show-delay': 500
    };
  }
}
