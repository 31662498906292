<app-navbar></app-navbar>
<div class="container mt-2">

  <div class="container animated fadeIn mt-5">
    <h1>Mantenedor de Productos</h1>
  </div>

  <hr class="mt-5">

  <div class="row mt-5 form-inline my-2 my-lg-0" *ngIf="!this.error">
    <div class="col text-left">
      <input class="form-control mr-sm-2" type="search" placeholder="Buscar un producto" aria-label="Search"
             (keyup.enter)="finderProduct(findProduct.value)"
             #findProduct>
      <button class="btn btn-outline-success my-2 my-sm-0 mr-2" type="button" (click)="finderProduct(findProduct.value)">
        <i class="fa fa-search">
        </i>
        Buscar
      </button>
      <button class="btn btn-outline-warning my-2 my-sm-0" type="button" (click)="cleanSearch()">
        <i class="fa fa-search">
        </i>
        Limpiar
      </button>
    </div>
    <div class="col text-right">
      <button routerLink="/mantenedores/producto/nuevo"
              class="btn btn-primary ">
        <i class="fa fa-plus">
        </i>
        Agregar
      </button>
    </div>
  </div>
  <div class="table-responsive">
    <table class="table animated fadeIn fast mx-auto w-fixed mt-5" *ngIf="!loading && products.length != 0">
      <thead class="thead-dark">
      <tr>
        <th scope="col">ID</th>
        <th scope="col" style="width: 20%">Nombre</th>
        <th scope="col">Cepa</th>
        <th scope="col">Año de Cosecha</th>
        <th scope="col">Producto</th>
        <th scope="col">Tipo de Vino</th>
        <th scope="col">Nivel</th>
        <th scope="col">Fecha Creación</th>
        <th scope="col">Acciones</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let product of products;">
        <th scope="row" class="animated fadeIn">{{product.id}}</th>
        <td>{{product.name}}</td>
        <td>{{product.strain}}</td>
        <td>{{product.harvest_year}}</td>
        <td>

          <span class="badge badge-primary" *ngIf="product.product_tp_id === 'SA'">Sin Asignar</span>
          <span class="badge badge-primary" *ngIf="product.product_tp_id === 'T'">Tinto</span>
          <span class="badge badge-secondary" *ngIf="product.product_tp_id === 'R'">Rosado</span>
          <span class="badge badge-warning" *ngIf="product.product_tp_id === 'B'">Blanco</span>
          <span class="badge badge-info" *ngIf="product.product_tp_id === 'E'">Espumante</span>
          <span class="badge badge-dark" *ngIf="product.product_tp_id === 'A'">Baya</span>
          <span class="badge badge-danger" *ngIf="product.product_tp_id === 'C'">Cooler</span>
        </td>
        <td>
          {{product.wine_internal_tp}}
        </td>
        <td>
          <span class="badge badge-success" *ngIf="product.level !== 'SA'">Nivel {{product.level}}</span>
          <span class="badge badge-light" *ngIf="product.level === 'SA'">Sin asignar</span>
        </td>
        <td>{{product.created_dt | date:'short'}}</td>
        <td>
          <button class="btn btn-info mr-1" [routerLink]="['/mantenedores/producto', product.id]" tooltip="Ver / Editar"
                  [options]="myOptions">
            <i class="fa fa-pen"></i>
          </button>
          <button class="btn btn-danger mr-1" (click)="deleteProduct(product)" tooltip="Eliminar" [options]="myOptions">
            <i class="fa fa-trash"></i>
          </button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <nav *ngIf="finderActive === false" class="nav justify-content-center">
    <ul class="pagination justify-content-center">
      <li [ngClass]="{'disabled':pager.currentPage === 1}">
        <a class="page-link selectable" (click)="setPage(1)">Primero</a>
      </li>
      <li [ngClass]="{disabled: pager.currentPage === 1 }">
        <a class="page-link selectable" (click)="fetchPageResults(pager.currentPage - 1)">Anterior</a>
      </li>
      <li *ngFor="let page of pager.pages" [ngClass]="{'active': pager.currentPage === page}">
        <a class="page-link selectable" (click)="fetchPageResults(page)">
          {{page}}
        </a>
      </li>
      <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
        <a class="page-link selectable" (click)="fetchPageResults(pager.currentPage + 1)">Siguiente</a>
      </li>
      <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
        <a class="page-link selectable"(click)="setPage(pager.totalPages)">Último</a>
      </li>
    </ul>
  </nav>

  <div class="alert alert-info text-center mt-3 animated fadeIn fast" *ngIf="loading">
    <h4 class="alert-heading">Cargando...</h4>
    <p>
      <i class="fa fa-sync-alt fa-spin fa-2x"></i>
    </p>
    <p class="mb-0">
      Espere por favor
    </p>
  </div>

  <div class="alert alert-warning text-center mt-3 animated fadeIn fast" *ngIf="!loading && products.length == 0">
    <h4 class="alert-heading">No hay registros</h4>
    <p>
      <i class="fa fa-exclamation fa-2x"></i>
    </p>
  </div>
</div>