export class ProductModel {
  id: string;
  name: string;
  strain: string;
  harvest_year: string;
  description: string;
  product_tp_id = 'SA';
  wine_internal_tp: string;
  level = 'SA';
  modality: string;
  modality_detail: string;
  created_dt: string;
  updated_dt: string;
}
