import {Component, OnInit} from '@angular/core';
import {LoginService} from '../../../services/login.service';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  swVersion = `${environment.SW_VERSION}`;
  constructor(public loginService: LoginService) {
  }

  ngOnInit(): void {
  }
}
