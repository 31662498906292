<app-navbar></app-navbar>
<div class="bg-img">
  <div class="container intro">
    <div class="jumbotron">
      <h1 class="display-4">Panel Sensorial</h1>
      <p class="lead">Visualización de datos</p>
      <hr class="my-4">
      <p>Bienvenido, puedes navegar en las opciones disponibles en el panel superior.</p>
    </div>
  </div>
</div>
