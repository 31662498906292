import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {FeatureModel} from '../models/feature.model';
import {environment} from '../../environments/environment';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';


@Injectable({
  providedIn: 'root'
})

export class FeaturesService {

  private URL_BASE = `${environment.API_URL}/api/descriptor`;

  constructor(private http: HttpClient,
              private modalService: NgbModal) {
  }


  createFeature(feature: FeatureModel): Observable<any> {
    return this.http.post(`${this.URL_BASE}`, feature)
      .pipe(
        map((response: any) => {
            feature.id = response.id;
            feature.created_dt = response.created_dt;
            feature.descriptor_detail = response.product_tp;
            feature.updated_dt = response.updated_dt;
            return feature;
          }
        )
      );
  }

  updateFeature(feature: FeatureModel): Observable<any> {
    return this.http.put(`${this.URL_BASE}/${feature.id}`, feature);
  }


  getFeatures(limit?: number, offset?: number): Observable<any> {
    const params = new HttpParams()
    .set('limit', String(limit))
    .set('offset', String(offset));
    return this.http.get(this.URL_BASE, {params});
  }


  getFeature(id: string): Observable<any> {
    return this.http.get(`${this.URL_BASE}/${id}`);
  }

  deleteFeature(id: string): Observable<HttpResponse<any>> {
    return this.http.delete(`${this.URL_BASE}/${id}`, {observe: 'response'});
  }

  getAllFeatures(wine_type?: string, country?: string): Observable<any> {
    let params = new HttpParams().set('limit', String(10000));

    if (wine_type !== '') {
      params = params.set('wine_type', String(wine_type));
    }

    if (country !== '') {
      params = params.set('country', String(country));
    }

    return this.http.get(this.URL_BASE, {params});
  }
}
