<app-navbar></app-navbar>
<div class="container mt-2">
  <div class="container animated fadeIn mt-5">
    <h1>Listar Sesiones</h1>
  </div>

  <hr class="mt-5">

  <div class="row mt-5 form-inline my-2 my-lg-0">
    <div class="col text-left">
      <input class="form-control mr-sm-2" type="search" placeholder="Buscar una sesión" aria-label="Search"
             (keyup.enter)="filterSession(sessionSearch.value)"
             #sessionSearch>

      <button class="btn btn-outline-success my-2 my-sm-0 mr-2" type="button"
              (click)="filterSession(sessionSearch.value)">
        <i class="fa fa-search">
        </i>
        Buscar
      </button>
      <button class="btn btn-outline-warning my-2 my-sm-0" type="button" (click)="cleanSearch()">
        <i class="fa fa-search">
        </i>
        Limpiar
      </button>
    </div>
  </div>

  <div class="row mt-5 form-inline my-2 my-lg-0 float-right">
    <div class="col float-right m-2">
      <button class="btn btn-outline-success my-1 my-sm-0" (click)="open(spreadsheet)" *ngIf="this.loginService.getRole() != 'PN'">
        <i class="fa fa-file-excel-o"></i>
        Cargar Evaluación
      </button>
    </div>
    <button routerLink="/sesiones/sesion/nuevo" class="btn btn-primary m-2" *ngIf="this.loginService.getRole() != 'PN'">
        <i class="fa fa-plus"></i>
        Agregar
      </button>
  </div>

  <ng-template #spreadsheet let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Seleccionar Evaluación a Cargar</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
      <input type="file" (change)="onChange($event)" accept=".xlsx">
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary m-2" (click)="onUpload()">Cargar</button>
    </div>
  </ng-template>

<div class="table-responsive">
    <table class="table animated fadeIn fast mx-auto w-fixed mt-5" [ngClass]="this.loginService.getRole() == 'PN' ? '' : 'w-auto'" *ngIf="!loading && sessions.length != 0">
    <thead class="thead-dark">
    <tr>
      <th scope="col" style="vertical-align: middle !important;">
        ID
      </th>

      <th scope="col" style="vertical-align: middle !important;">

        <div class="row text-center ml-1"><i class="fa fa-caret-up selectable"
                                             (click)="fetchOrderedSessions('name', true)">
        </i></div>
        <div class="row text-center ml-1">Nombre</div>
        <div class="row text-center ml-1"><i class="fa fa-caret-down selectable"
                                             (click)="fetchOrderedSessions('name', false)">
        </i>
        </div>
      </th>
      <th scope="col" style="vertical-align: middle !important;" *ngIf="this.loginService.getRole() != 'PN'">
        <div class="row ml-1"><i class="fa fa-caret-up selectable" (click)="fetchOrderedSessions('description', true)">
        </i></div>
        <div class="row ml-1 mr-2">Descripción</div>
        <div class="row ml-1"><i class="fa fa-caret-down selectable"
                                 (click)="fetchOrderedSessions('description', false)">
        </i></div>
      </th>
      <th scope="col" class="mr-2" style="vertical-align: middle !important;">
        <div class="row ml-1"><i class="fa fa-caret-up selectable" (click)="fetchOrderedSessions('session_dt', true)">
        </i></div>
        <div class="row ml-1">Fecha sesión</div>
        <div class="row ml-1"><i class="fa fa-caret-down selectable"
                                 (click)="fetchOrderedSessions('session_dt', false)">
        </i></div>
      </th>
      <th scope="col" style="vertical-align: middle !important;" *ngIf="this.loginService.getRole() != 'PN'">
        <div class="row ml-1"><i class="fa fa-caret-up selectable" (click)="fetchOrderedSessions('product_tp_id', true)">
        </i></div>
        <div class="row ml-1 mr-2">Producto</div>
        <div class="row ml-1"><i class="fa fa-caret-down selectable"
                                 (click)="fetchOrderedSessions('product_tp_id', false)">
        </i></div>
      </th>
      <th scope="col" style="vertical-align: middle !important;" *ngIf="this.loginService.getRole() != 'PN'">
        <div class="row ml-1"><i class="fa fa-caret-up selectable"
                                 (click)="fetchOrderedSessions('session_category_id', true)">
        </i></div>
        <div class="row ml-1">Solicitante</div>
        <div class="row ml-1"><i class="fa fa-caret-down selectable"
                                 (click)="fetchOrderedSessions('session_category_id', false)">
        </i></div>
      </th>
      <th scope="col" style="vertical-align: middle !important;" *ngIf="this.loginService.getRole() != 'PN'">
        <div class="row ml-1"><i class="fa fa-caret-up selectable" (click)="fetchOrderedSessions('status', true)">
        </i></div>
        <div class="row ml-1">Estado</div>
        <div class="row ml-1"><i class="fa fa-caret-down selectable" (click)="fetchOrderedSessions('status', false)">
        </i></div>
      </th>
      <th scope="col" style="vertical-align: middle !important;" *ngIf="this.loginService.getRole() != 'PN'">
        <div class="row ml-1"><i class="fa fa-caret-up selectable" (click)="fetchOrderedSessions('session_tp', true)">
        </i></div>
        <div class="row ml-1">Categoría</div>
        <div class="row ml-1"><i class="fa fa-caret-down selectable"
                                 (click)="fetchOrderedSessions('session_tp', false)">
        </i></div>
      </th>
      <th scope="col" style="vertical-align: middle !important;" *ngIf="this.loginService.getRole() != 'PN'">
        <div class="row ml-1"><i class="fa fa-caret-up selectable" (click)="fetchOrderedSessions('created_dt', true)">
        </i></div>
        <div class="row ml-1 mr-2">Creación</div>
        <div class="row ml-1"><i class="fa fa-caret-down selectable"
                                 (click)="fetchOrderedSessions('created_dt', false)">
        </i></div>
      </th>
      <th scope="col" style="vertical-align: middle !important;">Acciones</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let session of sessions;">
      <th scope="row" class="animated fadeIn">{{session.id}}</th>
      <td>{{session.name}}</td>
      <td *ngIf="this.loginService.getRole() != 'PN'">{{session.description}}</td>
      <td>{{session.session_dt | date: 'longDate'}}</td>
      <td *ngIf="this.loginService.getRole() != 'PN'">
        <span class="badge badge-primary" *ngIf="session.product_tp_id === 'SA'">Sin Asignar</span>
        <span class="badge badge-primary" *ngIf="session.product_tp_id === 'T'">Tinto</span>
        <span class="badge badge-secondary" *ngIf="session.product_tp_id === 'R'">Rosado</span>
        <span class="badge badge-warning" *ngIf="session.product_tp_id === 'B'">Blanco</span>
        <span class="badge badge-info" *ngIf="session.product_tp_id === 'E'">Espumante</span>
        <span class="badge badge-dark" *ngIf="session.product_tp_id === 'A'">Baya</span>
        <span class="badge badge-danger" *ngIf="session.product_tp_id === 'C'">Cooler</span>
      </td>

      <td *ngIf="this.loginService.getRole() != 'PN'">
        <span class="badge badge-primary" *ngIf="session.session_category_id === 'M'">Marketing</span>
        <span class="badge badge-secondary" *ngIf="session.session_category_id === 'I'">Innovación</span>
        <span class="badge badge-warning" *ngIf="session.session_category_id === 'C'">Catálogo</span>
        <span class="badge badge-info" *ngIf="session.session_category_id === 'A'">Calidad</span>
      </td>

      <td *ngIf="this.loginService.getRole() != 'PN'">
        <span class="badge badge-success" *ngIf="session.status === 'A'">Activo</span>
        <span class="badge badge-danger" *ngIf="session.status === 'I'">Inactivo</span>
      </td>
      <td *ngIf="this.loginService.getRole() != 'PN'">
        <span class="badge badge-warning" *ngIf="session.session_tp === 'G'">Generación de Perfil</span>
        <span class="badge badge-info" *ngIf="session.session_tp === 'C'">Comparación de Perfil</span>
        <span class="badge badge-dark" *ngIf="session.session_tp === 'T'">Triangular</span>
      </td>
      <td *ngIf="this.loginService.getRole() != 'PN'">{{session.created_dt | date:'short'}}</td>
      <td>
        <button class="btn btn-info m-1" title="Descargar" (click)="this.downloadSpreadsheet(session.id)" *ngIf="this.loginService.getRole() != 'PN'">
          <i class="fa fa-download"></i>
        </button>
        <button class="btn btn-info m-1" [routerLink]="['/sesiones/evaluar', session.id]" title="Evaluar">
          <i class="fa fa-check-square"></i>
        </button>
        <button class="btn btn-info m-1" *ngIf="this.loginService.getRole() != 'PN'" [routerLink]="['/sesiones/sesion', session.id]" title="Editar"
                [options]="myOptions">
          <i class="fa fa-pen"></i>
        </button>
        <button class="btn btn-info m-1" *ngIf="this.loginService.getRole() != 'PN'" [routerLink]="['/sesiones/analisis', session.id]" title="Análisis Químico">
          <i class="fa fa-bar-chart"></i>
        </button>
        <button class="btn btn-danger m-1" *ngIf="this.loginService.getRole() != 'PN'" (click)="deleteSession(session)" title="Eliminar" [options]="myOptions">
          <i class="fa fa-trash"></i>
        </button>
      </td>
    </tr>
    </tbody>
  </table>

</div>


<nav class="nav justify-content-center">
  <ul class="pagination justify-content-center">
    <li [ngClass]="{'disabled':pager.currentPage === 1}">
      <a class="page-link selectable" (click)="setPage(1)">Primero</a>
    </li>
    <li [ngClass]="{disabled: pager.currentPage === 1 }">
      <a class="page-link selectable" (click)="fetchPageResults(pager.currentPage - 1)">Anterior</a>
    </li>
    <li *ngFor="let page of pager.pages" [ngClass]="{'active': pager.currentPage === page}">
      <a class="page-link selectable" (click)="fetchPageResults(page)">
        {{page}}
      </a>
    </li>
    <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
      <a class="page-link selectable" (click)="fetchPageResults(pager.currentPage + 1)">Siguiente</a>
    </li>
    <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
      <a class="page-link selectable"(click)="setPage(pager.totalPages)">Último</a>
    </li>
  </ul>
</nav>

<div class="alert alert-info text-center mt-3 animated fadeIn fast" *ngIf="loading">
  <h4 class="alert-heading">Cargando...</h4>
  <p>
    <i class="fa fa-sync-alt fa-spin fa-2x"></i>
  </p>
  <p class="mb-0">
    Espere por favor
  </p>
</div>

<div class="alert alert-warning text-center mt-3 animated fadeIn fast" *ngIf="!loading && sessions.length == 0">
  <h4 class="alert-heading">No hay registros</h4>
  <p>
    <i class="fa fa-exclamation fa-2x"></i>
  </p>
</div>
</div>
