import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EvaluateService {
  private URL_BASE = `${environment.API_URL}/api/session`;

  constructor(private http: HttpClient) { }

  getStatistics(id, token): Observable<any> {
    return this.http.get(`${this.URL_BASE}/${id}/evaluations/statistics`, {headers: token});
  }

  getEvalSession(id, token): Observable<any> {
    return this.http.get(`${this.URL_BASE}/${id}/evaluation`);
  }

  registerEval(id, evaluations, token): Observable<any> {
    return this.http.post(`${this.URL_BASE}/${id}/evaluation`, {evaluations}, {headers: token})
      .pipe(
        map((response: any) => {
          return evaluations;
        })
      );
  }
}
