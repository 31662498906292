import {ActivatedRoute} from '@angular/router';
import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {LocalizationModel} from '../../../models/localization.model';
import {LocalizationService} from 'src/app/services/localization.service';
import Swal from 'sweetalert2';
import {Observable} from 'rxjs';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DualListComponent} from 'angular-dual-listbox';

@Component({
  selector: 'app-localization-mant',
  templateUrl: './localization.component.html',
  styleUrls: ['./localization.component.scss']
})

export class LocalizationComponent implements OnInit {
  localization = new LocalizationModel();
  previousUrl: string;

  countries: any[] = [
    {
      country: 'AR',
      name: 'Argentina'
    },
    {
      country: 'CL',
      name: 'Chile'
    }
  ];
  selectedCountries: any[] = [];
  listBoxFormat = {
    add: 'Agregar', remove: 'Remover', all: 'Todos', none: 'Ninguno',
    direction: DualListComponent.LTR, draggable: true, locale: 'es'
  };
  listBoxKey = 'country';
  listBoxDisplay = 'name';
  identifier = parseInt(this.route.snapshot.paramMap.get('id'));
  module = this.route.snapshot.paramMap.get('module');


  constructor(
    private localizationService: LocalizationService,
    private route: ActivatedRoute,
    private location: Location
  ) {
    this.previousUrl = this.location.path().split('/').slice(0, -1).join('/');
  }

  ngOnInit(): void {
    if (this.module === 'usuario'){
      this.module = 'user';
    }
    if (this.module === 'producto'){
      this.module = 'product';
    }
    if (this.module === 'sesion'){
      this.module = 'session';
    }
    if (this.module === 'grupopanelistas'){
      this.module = 'panelist_group';
    }


    this.localizationService.getLocalization(this.identifier, this.module).subscribe(response => {
      this.localization = response.data;
      this.selectedCountries = response.data;
    });
  }

  saveLocalization(): void {
    Swal.fire({
      icon: 'info',
      title: 'Espere...',
      text: 'Guardando información!',
      allowOutsideClick: false
    });
    Swal.showLoading();

    let request: Observable<any>;

    request = this.localizationService.saveLocalization(this.identifier, this.module, this.selectedCountries);

    request.subscribe(response => {
      Swal.fire({
        icon: 'success',
        title: 'Localización',
        text: 'Se guardó correctamente'
      });
    }, error => {
      Swal.fire({
        icon: 'error',
        title: 'Localización',
        text: 'Ocurrió un error al guardar los datos'
      });
    });
  }
}
