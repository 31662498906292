import {Component, OnInit} from '@angular/core';
import {PanelistsService} from '../../../services/panelists.service';
import {ActivatedRoute} from '@angular/router';
import {PanelistGroupModel} from '../../../models/panelist.group.model';
import {NgForm} from '@angular/forms';
import Swal from 'sweetalert2';
import {Observable} from 'rxjs';
import {SimpleUserModel} from '../../../models/simple.user.model';
import {DualListComponent} from 'angular-dual-listbox';
import {UsersService} from '../../../services/users.service';
import {LocalizationService} from 'src/app/services/localization.service';
import {LoginService} from 'src/app/services/login.service';

@Component({
  selector: 'app-panelistgroup',
  templateUrl: './panelistgroup.component.html',
  styleUrls: ['./panelistgroup.component.scss']
})
export class PanelistgroupComponent implements OnInit {

  panelist = new PanelistGroupModel();
  users: any[];
  usersBackUp: any[] = [];
  selectedUsers: any[] = [];
  listBoxFormat = {
    add: 'Agregar', remove: 'Remover', all: 'Todos', none: 'Ninguno',
    direction: DualListComponent.LTR, draggable: true, locale: 'es'
  };
  listBoxKey = 'id';
  listBoxDisplay = 'name_email';

  constructor(
    private localizationService: LocalizationService,
    private panelistService: PanelistsService,
    private route: ActivatedRoute,
    private usersService: UsersService,
    public loginService: LoginService
    ) {}

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
    if (id !== 'nuevo') {
      this.panelistService.getPanelistGroupDetails(id).subscribe(response => {
        this.panelist = response;
        response.users.map(user => {
          user.name_email = 'ID ' + user.id + ' - ' + user.name + ' - ' + user.email;
        });
        this.users = response.users;
        this.selectedUsers = response.users;
      });
    }

    this.usersService.getUsers(100000, 0).subscribe(response => {
      response.data.map(user => {
        user.name_email = 'ID ' + user.id + ' - ' + user.name + ' - ' + user.email;
      });
      this.users = response.data;
      this.usersBackUp = response.data;
    });
  }

  savePanelistGroup(form: NgForm): void {
    if (form.invalid) {
      Object.values(form.controls).forEach(control => {
        control.markAsTouched();
      });
      return;
    }
    Swal.fire({
      icon: 'info',
      title: 'Espere...',
      text: 'Guardando información!',
      allowOutsideClick: false
    });
    Swal.showLoading();

    let request: Observable<any>;

    const usersList = [];
    this.selectedUsers.forEach(x => {
      if (x.hasOwnProperty('id')){
        usersList.push(x['id']);
      }else{
        usersList.push(x);
      }
    });

    this.panelist.users = usersList;

    if (this.panelist.id) {
      request = this.panelistService.updatePanelistGroup(this.panelist);
    } else {
      request = this.panelistService.createPanelistGroup(this.panelist);
    }

    request.subscribe(response => {
      Swal.fire({
        icon: 'success',
        title: this.panelist.name,
        text: 'Se guardó correctamente'
      });
    }, error => {
      Swal.fire({
        icon: 'error',
        title: this.panelist.name,
        text: 'Ocurrió un error al guardar los datos'
      });
    });
  }


  filterUsers(searchTerm: string): void {
    this.users = this.usersService.searchUser(searchTerm, this.usersBackUp);
  }
}
