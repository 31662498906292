import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {GuestModel} from '../models/guest_login.model';
import Swal from 'sweetalert2';
import {LoginService} from './login.service';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PasswordService {
  private baseUrl = `${environment.API_URL}/api/user`;
  public message: string;
  public guest_model = new GuestModel();

  constructor(private http: HttpClient, public loginService: LoginService, private router: Router) {
  }

  updateCredentials(): void {
    const current_password = this.guest_model.current_password;
    const new_password = this.guest_model.new_password;

    if (!current_password) {
      Swal.fire({
        icon: 'warning',
        title: 'Credenciales',
        text: 'Debe ingresar la contraseña actual'
      });
    } else {
    if (this.sendRequest()) {
      const guest_data = this.http.patch<any>(this.baseUrl + '/change-password',
        {
          current_password: current_password,
          new_password: new_password,
          token: this.loginService.getToken()
        });

      guest_data.subscribe(data => {
        if (data) {
          Swal.fire({
            icon: 'info',
            title: 'Credenciales',
            text: 'Contraseña actualizada satisfactoriamente.'
          });
          this.guest_model.current_password = null;
          this.guest_model.new_password = null;
          this.guest_model.repeat_new_password = null;
          this.router.navigateByUrl('/inicio');
        } else {
          this.guest_model.current_password = null;
          this.guest_model.new_password = null;
          this.guest_model.repeat_new_password = null;
          Swal.fire({
            icon: 'warning',
            title: 'Credenciales',
            text: 'Contraseña actual incorrecta'
          });
        }
      }, error => {
        Swal.fire({
          icon: 'warning',
          title: 'No Permitido',
          text: error.error.message
        });
      });
    }
  }
  }

  checkPassword(): string[] {
    const messages: string[] = [];

    if (!!this.guest_model.new_password && this.guest_model.new_password === this.guest_model.repeat_new_password) {
      messages.push('Las contraseñas coinciden');
    }

    if (this.guest_model.new_password.length >= 8) {
      messages.push('Largo con al menos 8 caracteres');
    }

    if (this.guest_model.new_password.toLowerCase() !== this.guest_model.new_password) {
      messages.push('Contraseña con al menos una letra minúscula');
    }

    if (this.guest_model.new_password.toUpperCase() !== this.guest_model.new_password) {
      messages.push('Contraseña con al menos una letra mayúscula');
    }

    if (/\d/.test(this.guest_model.new_password)){
      messages.push('Contraseña con al menos un dígito');
    }

    if (/[ `/!¡@#$%^&*()_+\-=\]{};':"\\|,.<>¿?~]/.test(this.guest_model.new_password)) {
      messages.push('Contraseña con al menos un símbolo');
    }

    return messages;
  }

  sendRequest(): boolean {
    if (this.checkPassword().length === 6) {
      return true;
    } else {
      return false;
    }
  }
}
