<app-navbar></app-navbar>
<div class="container mt-2">

  <div class="container animated fadeIn mt-5">
    <h1>Usuarios</h1>
  </div>

  <hr class="mt-5">

  <div class="row mt-5 form-inline my-2 my-lg-0" *ngIf="!this.error">
    <div class="col text-left">
      <input class="form-control mr-sm-2" type="search" placeholder="Buscar un usuario" aria-label="Search"
             (keyup.enter)="finderUser(findUser.value)"
             #findUser>
      <button class="btn btn-outline-success my-2 my-sm-0 mr-2" type="button" (click)="finderUser(findUser.value)">
        <i class="fa fa-search">
        </i>
        Buscar
      </button>
      <button class="btn btn-outline-warning my-2 my-sm-0" type="button" (click)="cleanSearch()">
        <i class="fa fa-search">
        </i>
        Limpiar
      </button>
    </div>

    <div class="col text-right">
      <button routerLink="/mantenedores/usuario/nuevo/visitante"
              class="btn btn-primary ">
        <i class="fa fa-plus">

        </i>
        Agregar
      </button>
    </div>
  </div>

  <table class="table animated fadeIn fast mx-auto w-fixed mt-5" *ngIf="!loading && users.length != 0">
    <thead class="thead-dark">
    <tr>
      <th scope="col">#</th>
      <th scope="col" style="width: 20%">Nombre</th>
      <th scope="col">Email</th>
      <th scope="col">Estado</th>
      <th scope="col">Rol de usuario</th>
      <th scope="col">Fecha Creación</th>
      <th scope="col">Acciones</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let user of users">
      <th scope="row">{{user.id}}</th>
      <td>{{user.name}}</td>
      <td>{{user.email}}</td>
      <td>
        <select [(ngModel)]="user.is_active" disabled>
          <option value="true">Activo</option>
          <option value="false">Inactivo</option>
        </select>
      </td>
      <td>
        <select [(ngModel)]="user.role_type" disabled>
          <option value="MK">Marketing</option>
          <option value="RS">Investigación</option>
          <option value="QT">Calidad</option>
          <option value="VT">Visitante</option>
          <option value="PN">Panelista</option>
          <option value="CR">Creado</option>
        </select>
      </td>
      <td>{{user.created_dt | date:'short'}}</td>
      <td>
        <button class="btn btn-info mr-1" [routerLink]="['/mantenedores/usuario', user.id, user.role_type]">
          <i class="fa fa-pen"></i>
        </button>
        <button class="btn btn-danger mr-1" (click)="deleteUser(user)">
          <i class="fa fa-trash"></i>
        </button>
      </td>
    </tr>
    </tbody>
  </table>

<nav class="nav justify-content-center">
  <ul class="pagination justify-content-center">
    <li [ngClass]="{'disabled':pager.currentPage === 1}">
      <a class="page-link selectable" (click)="setPage(1)">Primero</a>
    </li>
    <li [ngClass]="{disabled: pager.currentPage === 1 }">
      <a class="page-link selectable" (click)="fetchPageResults(pager.currentPage - 1)">Anterior</a>
    </li>
    <li *ngFor="let page of pager.pages" [ngClass]="{'active': pager.currentPage === page}">
      <a class="page-link selectable" (click)="fetchPageResults(page)">
        {{page}}
      </a>
    </li>
    <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
      <a class="page-link selectable" (click)="fetchPageResults(pager.currentPage + 1)">Siguiente</a>
    </li>
    <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
      <a class="page-link selectable"(click)="setPage(pager.totalPages)">Último</a>
    </li>
  </ul>
</nav>

<div class="alert alert-info text-center mt-3 animated fadeIn fast" *ngIf="loading">
  <h4 class="alert-heading">Cargando...</h4>
  <p>
    <i class="fa fa-sync-alt fa-spin fa-2x"></i>
  </p>
  <p class="mb-0">
    Espere por favor
  </p>
</div>

  <div class="alert alert-warning text-center mt-3 animated fadeIn fast" *ngIf="!loading && users.length == 0">
    <h4 class="alert-heading">No hay registros</h4>
    <p>
      <i class="fa fa-exclamation fa-2x"></i>
    </p>
  </div>
</div>