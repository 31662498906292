import { Component, OnInit } from '@angular/core';
import { UsersService } from '../../../services/users.service';
import { UserModel } from '../../../models/user.model';
import Swal from 'sweetalert2';
import {PaginationModel} from '../../../models/pagination.model';
import {UtilsService} from '../../../services/utils.service';
import {Router} from "@angular/router";
import {environment} from '../../../../environments/environment';
import {getPager} from 'ap-pagination';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  offset = environment.PAGINATION_SIZE;
  limit = environment.PAGINATION_SIZE;

  allItems: any[];
  pager: any = {};
  pagedItems: any[];

  users: UserModel[] = [];
  loading = false;
  error = false;
  pagination: PaginationModel = new PaginationModel();
  usersBackUp: UserModel[] = [];

  usersList: any = [];

  constructor(private usersService: UsersService,
              private utilService: UtilsService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.loading = true;
    this.usersService.getUsers(this.limit, 0).subscribe(response => {
      this.allItems = Array(response.pagination.total).fill(1);
      this.setPage(1);
      this.pagination.totalElements = response.pagination.total;
      this.pagination.nextPage = response.pagination.next;
      this.users = response.data;
      this.loading = false;
      this.usersBackUp = response.data;
      this.pagination = this.utilService.createPaginator(this.pagination);
    }, error => {
      this.loading = false;
      this.error = true;
      const status_code = error.status;
      const status_text = error.statusText;

      if (status_code === 401) {
        this.router.navigateByUrl('/login');
        Swal.fire({
          icon: 'warning',
          title: 'Obtener Usuarios',
          text: status_code + ' - ' + status_text
        });
      } else if (status_code === 403) {
        this.router.navigateByUrl('/inicio');
        Swal.fire({
          icon: 'warning',
          title: 'Obtener Usuarios',
          text: error.error.message
        });
      }
    });
  }

  deleteUser(user: UserModel): void {

    Swal.fire({
      icon: 'question',
      title: '¿Está seguro?',
      text: `Está seguro de que desea borrar el usuario: ${user.name}`,
      showConfirmButton: true,
      showCancelButton: true
    }).then(response => {
      if (response.value) {
        this.usersService.deleteUser(user.id)
          .subscribe((rs: any) => {
            if (rs.status === 200){
              Swal.fire({
                icon: 'info',
                title: 'Usuario eliminado correctamente',
                text: rs.body.message
              });
              const index = this.users.indexOf(user);
              this.users.splice(index, 1);
            }
          },
            (errorResponse: any) => {
              if (errorResponse.status === 400){
                Swal.fire({
                  icon: 'error',
                  title: "No se puede eliminar",
                });
              }
            });
      }
    });
  }

  createPaginator(): void {
    // Todos los todos los elementos.
    if (this.pagination.nextPage) {
      // Calcular cantidad de elementos por página
      const limit = this.pagination.nextPage.match(/limit=(.*?)&/);
      const limitNumber = parseInt(limit[1]);
      this.pagination.pagesQuantity = Math.ceil(this.pagination.totalElements / limitNumber);

      const pagesArray = Array.from({length: this.pagination.pagesQuantity}, (_, i) => i + 1);
      // First Page
      pagesArray.splice(-1, 1);
      this.pagination.pages.push(`?limit=${limitNumber}`);
      pagesArray.forEach(element => {
        this.pagination.pages.push(`?limit=${limitNumber}&offset=${limitNumber * element}`);
      });
    } else {
      this.pagination.pagesQuantity = 1;
    }
  }

  fetchPageResults(pageQuery?: number): void {
    let new_offset = this.offset;
    let query: string;

    if (pageQuery !== 1) {
      new_offset = (this.offset * (pageQuery - 1));
    }else{
      new_offset = 0;
    }

    if (pageQuery <= this.pager.totalPages) {
      this.usersService.getUsers(this.limit, new_offset).subscribe(response => {
        this.users = response.data;
        this.pager.currentPage = pageQuery;
        this.pager = getPager(this.allItems.length, pageQuery, this.limit);
        this.pagedItems = this.allItems.slice(this.pager.startIndex, this.pager.endIndex + 1);
      });
    }
  }

  finderUser(searchTerm: string): void {
    if (searchTerm === undefined || searchTerm.trim() === ''){
      return;
    }
    this.loading = true;
    this.utilService.findByTerm(searchTerm, 'user')
      .subscribe(response => {
        this.users = response.data;
        this.loading = false;
      }, error => {
        this.loading = false;
        Swal.fire({
          icon: 'error',
          title: 'Buscar usuarios',
          text: 'Ocurrió un error al obtener los datos'
        });
      });
  }

  cleanSearch(): void {
    this.users = this.usersBackUp;
  }

  setPage(page): void {
    this.pager = getPager(this.allItems.length, page, 5);
    this.pagedItems = this.allItems.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }
}
