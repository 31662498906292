import {ActivatedRoute} from '@angular/router';
import {Component, OnInit} from '@angular/core';
import {FeatureModel} from '../../../models/feature.model';
import {FeaturesService} from 'src/app/services/features.service';
import {NgForm} from '@angular/forms';
import Swal from 'sweetalert2';
import {Observable} from 'rxjs';
import {DualListComponent} from 'angular-dual-listbox';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LoginService} from 'src/app/services/login.service';

@Component({
  selector: 'app-feature-mant',
  templateUrl: './feature-mant.component.html',
  styleUrls: ['./feature-mant.component.scss']
})
export class FeatureMantComponent implements OnInit {
  descriptor = new FeatureModel();
  wineTypes: any[] = [{
    code: 'T',
    name: 'Vino Tinto'
  },
    {
      code: 'R',
      name: 'Vino Rosado'
    },
    {
      code: 'B',
      name: 'Vino Blanco'
    },
    {
      code: 'E',
      name: 'Espumantes'
    },
    {
      code: 'A',
      name: 'Baya'
    },
    {
      code: 'C',
      name: 'Cooler'
    },
  ];
  selectedWineTypes: any[] = [];

  showDualList = false;
  listBoxFormat = {
    add: 'Agregar', remove: 'Remover', all: 'Todos', none: 'Ninguno',
    direction: DualListComponent.LTR, draggable: true, locale: 'es'
  };
  listBoxKey = 'code';
  listBoxDisplay = 'name';

  constructor(private featureService: FeaturesService,
              private route: ActivatedRoute,
              private modalService: NgbModal,
              public loginService: LoginService
              ) {
  }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
    if (id !== 'nuevo') {
      this.featureService.getFeature(id).subscribe(response => {
        this.descriptor = response;
        response.descriptor_detail.forEach(item => {
          if (item.selected === true) {
            this.selectedWineTypes.push(item.wine_type_id);
          }
        });
      });
    }
  }

  saveFeature(form: NgForm): void {
    if (form.invalid) {
      Object.values(form.controls).forEach(control => {
        control.markAsTouched();
      });
      return;
    }

    this.descriptor.descriptor_detail = [];
    this.selectedWineTypes.forEach(x => {
      if (x.hasOwnProperty('code')) {
        this.descriptor.descriptor_detail.push(x.code);
      } else {
        this.descriptor.descriptor_detail.push(x);
      }
    });

    Swal.fire({
      icon: 'info',
      title: 'Espere...',
      text: 'Guardando información!',
      allowOutsideClick: false
    });
    Swal.showLoading();

    const missingItems = this.wineTypes.filter(type => !this.descriptor.descriptor_detail.includes(type.code));
    const featureProducts: {}[] = [];

    this.descriptor.descriptor_detail.forEach(item => {
      featureProducts.push(
        {
          wine_type_id: item,
          selected: true
        }
      );
    });

    missingItems.forEach(item => {
      featureProducts.push(
        {
          wine_type_id: item.code,
          selected: false
        }
      );
    });

    let request: Observable<any>;
    this.descriptor.descriptor_detail = featureProducts;
    if (this.descriptor.id) {
      request = this.featureService.updateFeature(this.descriptor);
    } else {
      request = this.featureService.createFeature(this.descriptor);
    }

    request.subscribe(response => {
      Swal.fire({
        icon: 'success',
        title: this.descriptor.name,
        text: 'Se guardó correctamente'
      });
    }, error => {
      Swal.fire({
        icon: 'error',
        title: this.descriptor.name,
        text: 'Ocurrió un error al guardar los datos'
      });
    });
  }
}
