import {Component, OnInit} from '@angular/core';
import {SessionModel} from '../../../models/session.model';
import {SessionsService} from '../../../services/sessions.service';
import {ActivatedRoute} from '@angular/router';
import {NgForm} from '@angular/forms';
import Swal from 'sweetalert2';
import {Observable} from 'rxjs';
import {ProductsService} from '../../../services/products.service';
import {DualListComponent} from 'angular-dual-listbox';
import {FeaturesService} from '../../../services/features.service';
import {PanelistsService} from '../../../services/panelists.service';
import {LocalizationService} from 'src/app/services/localization.service';
import {LoginService} from 'src/app/services/login.service';
import {MatTableDataSource} from '@angular/material/table';
import {MatCheckboxChange} from "@angular/material/checkbox";


export interface Product {
  calibrate: boolean;
  id: number;
  name: string;
  description: string;
  harvest_year: string;
  level: string;
  wine_internal_tp: string;
  include_visualization: boolean;
}


@Component({
  selector: 'app-session',
  templateUrl: './session.component.html',
  styleUrls: ['./session.component.scss']
})
export class SessionComponent implements OnInit {
  displayedColumns: string[] = ['calibrate', 'id', 'name', 'description', 'harvest_year', 'level', 'wine_internal_tp', 'include_visualization'];
  dataSource = new MatTableDataSource<Product>([]);



  selectedCountry: string = '';
  session = new SessionModel();
  retrievedProducts: any[] = [];
  products: any[] = [];
  productsBackUp: any[] = [];
  descriptors: any[] = [];
  selectedProducts: any[] = [];
  saved_products: any[] = [];
  showDualList = false;
  showFeatures = false;
  currentStep: any;
  panelistsGroups: any[] = [];
  selectedPanelistsGroups: any[] = [];
  listBoxFormat = {
    add: 'Agregar', remove: 'Remover', all: 'Todos', none: 'Ninguno',
    direction: DualListComponent.LTR, draggable: true, locale: 'es'
  };
  listBoxKey = 'id';
  listBoxDisplay = 'name_strain';
  listBoxDisplayPanelists = 'name';
  countries: any[] = [
    {
      country: 'AR',
      name: 'Argentina'
    },
    {
      country: 'CL',
      name: 'Chile'
    }
  ];
  selectedCountries: any[] = [];

  constructor(
    private sessionService: SessionsService,
    private productService: ProductsService,
    private featureService: FeaturesService,
    private panelistService: PanelistsService,
    private localizationService: LocalizationService,
    private route: ActivatedRoute,
    public loginService: LoginService
    ) {}

  ngOnInit(): void {
    this.retrievedProducts = [];
    const id = this.route.snapshot.paramMap.get('id');
    this.currentStep = 'showStep1';

    if (id !== 'nuevo') {
      this.sessionService.getSession(id).subscribe(response => {
        this.session = response;
        this.selectedProducts = response.products;
        this.descriptors = response.descriptors;
        this.selectedPanelistsGroups = response.panelist_groups;

        this.localizationService.getLocalization(parseInt(id), 'session').subscribe(localization => {
          this.selectedCountries = localization.data;
        });
        this.selectedCountry = this.session.country;

        this.dataSource.data = this.selectedProducts;
        this.dataSource.data.forEach(row => {
          row.calibrate = row.id === this.session.session_calibration_product;
        });
        this.getProductsAndFeaturesByWineType(this.session.product_tp_id, this.descriptors);

        this.panelistsGroups = response.panelist_groups;
        this.panelistService.getPanelistGroups(100, 0).subscribe(panelists => {
          this.panelistsGroups = panelists.data;
        });
      });
    } else {
      this.panelistService.getPanelistGroups(100, 0).subscribe(panelists => {
        this.panelistsGroups = panelists.data;
      });
    }
  }

  saveSession(form: NgForm): void {
    if (!form.valid) {
      Object.values(form.controls).forEach(control => {
        control.markAsTouched();
      });
      Swal.fire({
        icon: 'error',
        title: this.session.name,
        text: 'Hay campos inválidos o faltantes en la creación de la sesión'
      });
      return;
    }
    this.session.descriptors = this.descriptors.filter((obj: any) => obj.selected).map(({id}) => id);
    let index_value = 0;
    this.selectedProducts.forEach(element => {
      element.product_id = element.id;
      element.name = element.name;
      element.product_order = index_value;
      index_value++;
    });
    this.session.session_product = this.selectedProducts;
    this.session.products = this.selectedProducts.map(({id}) => id);
    this.session.panelist_groups = this.selectedPanelistsGroups.map(({id}) => id);
    this.session.country = this.selectedCountry;


    Swal.fire({
      icon: 'info',
      title: 'Espere...',
      text: 'Guardando información!',
      allowOutsideClick: false
    });
    Swal.showLoading();

    let request: Observable<any>;

    if (this.session.id) {
      request = this.sessionService.updateSession(this.session);
    } else {
      request = this.sessionService.createSession(this.session);
    }

    request.subscribe(response => {
      Swal.fire({
        icon: 'success',
        title: this.session.name,
        text: 'Se guardó correctamente'
      });
    }, errorResponse => {
      Swal.fire({
        icon: 'warning',
        title: this.session.name,
        text: errorResponse.error.message
      });
    });
  }

  getProductsAndFeaturesByWineType(sessionType: string, selectedFeatures?: any[]): void {
    this.showFeatures = false;
    this.products = [];

    if (sessionType !== '') {
      this.productService.getAllProducts(sessionType, this.selectedCountry).subscribe(response => {
        response.data.map(prod => {
          let product_name;
          if (prod.strain !== null){
            product_name = prod.name + ' - ' + prod.strain;
          }
          else{
            product_name = prod.name;
          }

          prod.name_strain = 'ID ' + prod.id + ' - ' + product_name;

          this.products.push(prod);

          return prod;
        });
        //this.products = allProducts;
        this.productsBackUp = this.products;
      });

      this.featureService.getAllFeatures(sessionType, this.selectedCountry).subscribe(response => {
        response.data.forEach(feature => feature.selected = true);
        this.descriptors = response.data;
        this.showFeatures = true;

        if (selectedFeatures) {
          this.descriptors.forEach(feature => delete feature.selected);
          this.descriptors.forEach(feature => feature.selected = selectedFeatures.some(x => x.id === feature.id));
        }
        this.session.descriptors = this.descriptors.filter((obj: any) => obj.selected).map(({id}) => id);
      });
    }
  }

  changeFeatureStatus(index): void {
    this.descriptors[index].selected = !this.descriptors[index].selected;
    this.session.descriptors = this.descriptors.filter((obj: any) => obj.selected).map(({id}) => id);
  }

  setMultiStep(id: number): void {
    this.paintProgressBar(id);
    this.currentStep = `showStep${id}`;
  }

  paintProgressBar(id: number): void {
    Array.from({length: id}, (_, i) => i + 1).reverse().forEach(el => {

      document.getElementById(`step${el}`).classList.add('js-active');
    });

    Array.from(new Array(6 - id), (x, i) => i + (id + 1)).forEach(el => {
      document.getElementById(`step${el}`).classList.remove('js-active');
    });
  }

  filterProducts(searchTerm: string): void {
    this.products = this.productService.searchProduct(searchTerm, this.productsBackUp);
  }

  sortSelectedProducts(products: any[], session_product: any[]): any[]{
    this.retrievedProducts = [];
    this.saved_products = [];

    session_product.forEach(element => {
      const product = products.find(x => x.id === element.product_id);
      this.saved_products.push(product);
      products.forEach(item_product => {
        if (item_product.id === element.product_id) {
          this.retrievedProducts.push(item_product);
        }
      });
    });

    return this.retrievedProducts;
  }

  onDualListBoxSelectionChange() {
    this.dataSource.data = this.selectedProducts;
    this.dataSource.data.forEach(element => {
      element.include_visualization = true;
    });
  }

  loadLocalizedData() {
    this.dataSource.data = [];
    this.session.products = [];
    this.selectedProducts = [];
    this.selectedPanelistsGroups = [];
    this.getProductsAndFeaturesByWineType(this.session.product_tp_id)
  }

  setCalibrationProduct(event: MatCheckboxChange, product: Product) {
    this.dataSource.data.forEach(row => {
      if (event.checked && row.id == product.id) {
        this.session.session_calibration_product = product.id;
        row.calibrate = true;
      }else{
        row.calibrate = false;
      }
    });

  }

  setVisibleForVisualization(event: MatCheckboxChange, product: Product) {
    product.include_visualization = event.checked;
    this.selectedProducts.forEach(element => {
      if (product.id == element.id) {
        element.include_visualization = product.include_visualization;
      }
    });
  }

  getCalibrationProduct(): string {
    let calibrationProduct: string = '';
    this.selectedProducts.forEach(product => {
      if (product.calibrate) {
        calibrationProduct = `${product.name} - ID ${product.id}`
      }
    });
    return calibrationProduct;
  }
}
