export class SessionModel {
  id: string;
  name: string;
  description: string;
  session_dt: string;
  session_tp = '';
  product_tp_id = '';
  status = 'A' ;
  session_category_id = '';
  products: any[] = [];
  session_product: any[] = [];
  descriptors: any[] = [];
  panelist_groups: any[] = [];
  created_dt: string;
  updated_dt: string;
  session_calibration_product = 0;
  country: string = '';
}
