<app-navbar></app-navbar>
<div class="container mt-2">
  <div class="container mt-5">
    <h1 *ngIf="this.session.id" class="animated fadeIn fast">Viendo Sesión: <small>{{this.session.name}}</small></h1>
    <h1 *ngIf="!this.session.id" class="animated fadeIn fast">Crear Nueva Sesión <small>{{this.session.name}}</small>
    </h1>
    <div class="text-right mt-5">
      <button class="btn btn-danger" routerLink="/sesiones">
        <i class="fa fa-arrow-left"></i>
        Ver todas las sesiones
      </button>
    </div>
    <div class="multisteps-form__form">
      <hr>
      <div class="row">
        <div class="col-12 col-lg-8 ml-auto mr-auto mb-4">
          <div class="multisteps-form__progress">
            <button class="multisteps-form__progress-btn js-active pointer" type="button" title="Tipo de Vino"
                    id="step1"
                    (click)="setMultiStep(1)">Producto
            </button>
            <button class="multisteps-form__progress-btn pointer" type="button" title="Detalle sesión" id="step2"
                    (click)="setMultiStep(2)">Detalle
            </button>
            <button class="multisteps-form__progress-btn pointer" type="button" title="Productos" id="step3"
                    (click)="setMultiStep(3)">Productos
            </button>
            <button class="multisteps-form__progress-btn pointer" type="button" title="Descriptores" id="step4"
                    (click)="setMultiStep(4)">Descriptores
            </button>
            <button class="multisteps-form__progress-btn pointer" type="button" title="Panelistas" id="step5"
                    (click)="setMultiStep(5)">Panelistas
            </button>
            <button class="multisteps-form__progress-btn pointer" type="button" title="Guardar" id="step6"
                    (click)="setMultiStep(6)">Guardar
            </button>
          </div>
        </div>

      </div>
      <hr>
      <!--form panels-->
      <div class="row animated fadeIn fast">
        <div class="col">
          <form (ngSubmit)="saveSession(form)" #form="ngForm">
            <div class="form-group shadow p-4 rounded" id="showStep1"
                 [ngClass]="currentStep === 'showStep1' ? '': 'd-none'">
              <h3 class="mt-2" *ngIf="session.id">Producto</h3>
              <h3 class="mt-2" *ngIf="!session.id">Seleccione producto de la sesión</h3>
              <hr>
              <select class="form-control mt-3"
                      required
                      #product_tp="ngModel"
                      (change)="getProductsAndFeaturesByWineType(session.product_tp_id)"
                      name="product_tp_id"
                      [class.is-invalid]="product_tp.invalid && product_tp.touched"
                      [class.is-valid]="product_tp.valid"
                      [(ngModel)]="session.product_tp_id">
                <option value="">[Seleccionar un producto]</option>
                <option value="T">Vino Tinto</option>
                <option value="R">Vino Rosado</option>
                <option value="B">Vino Blanco</option>
                <option value="E">Espumantes</option>
                <option value="A">Bayas</option>
                <option value="C">Cooler</option>
              </select>
              <small class="form-text text-danger"
                     *ngIf="product_tp.invalid && product_tp.touched">El producto es obligatorio.</small>

              <div class="form-group text-center mt-5">
                <a class="btn btn-primary w-25" (click)="setMultiStep(2)">
                  <i class="fa fa-arrow-right"></i>
                  Siguiente
                </a>
              </div>

            </div>
            <div id="showStep2" class="shadow p-4 rounded " [ngClass]="currentStep === 'showStep2' ? '': 'd-none'">
              <div class="form-group">
                <label>ID</label>
                <input type="text"
                       class="form-control"
                       placeholder="ID de la sesión"
                       disabled="disabled"
                       [(ngModel)]="session.id"
                       name="id">
                <small class="form-text text-muted">Este campo es autogenerado</small>
              </div>

              <div class="form-group">
                <label>Nombre visible</label>
                <input type="text"
                       class="form-control"
                       placeholder="Ingrese nombre visible para panelistas"
                       required
                       minlength="5"
                       [(ngModel)]="session.name"
                       #name="ngModel"
                       [class.is-invalid]="name.invalid && name.touched"
                       [class.is-valid]="name.valid"
                       name="name">
                <small class="form-text text-danger"
                       *ngIf="name.invalid && name.touched">El campo nombre es obligatorio y con al menos 5
                  letras.</small>
              </div>

              <div class="form-group">
                <label>Descripción interna</label>
                <textarea class="form-control"
                          placeholder="Ingresar descripción de uso interno"
                          required
                          minlength="5"
                          [(ngModel)]="session.description"
                          #description="ngModel"
                          [class.is-invalid]="description.invalid && description.touched"
                          [class.is-valid]="description.valid"
                          name="description">

      </textarea>
                <small class="form-text text-danger" *ngIf="description.invalid && description.touched">El campo
                  descripción es obligatorio y con al menos 5 letras.</small>
              </div>

              <div class="form-group">
                <label>Fecha</label>
                <input type="date"
                       class="form-control"
                       placeholder="Fecha de la sesión"
                       [(ngModel)]="session.session_dt"
                       #session_dt="ngModel"
                       name="session_dt">
              </div>

              <div class="form-group">
                <label>País</label>
                <select class="form-control" required [(ngModel)]="selectedCountry" (change)="loadLocalizedData()">
                  <option value="">[Seleccionar un país]</option>
                  <option value="AR">Argentina</option>
                  <option value="CL">Chile</option>
                </select>
              </div>

              <div class="form-group">
                <label>Tipo de la sesión</label>
                <select class="form-control"
                        required
                        #session_tp="ngModel"
                        name="session_tp"
                        [class.is-invalid]="session_tp.invalid && session_tp.touched"
                        [class.is-valid]="session_tp.valid"
                        [(ngModel)]="session.session_tp"
                >
                  <option value="">[Seleccionar un tipo]</option>
                  <option value="G">Generación de Perfil</option>
                  <option value="C">Comparación de Perfil</option>
                  <option value="T">Triangular</option>
                </select>
                <small class="form-text text-danger"
                       *ngIf="session_tp.invalid && session_tp.touched">El tipo de la sesión es obligatorio.</small>
              </div>

              <div class="form-group">
                <label>Solicitante</label>
                <select class="form-control"
                        required
                        #session_category="ngModel"
                        name="session_category"
                        [class.is-invalid]="session_category.invalid && session_category.touched"
                        [class.is-valid]="session_category.valid"
                        [(ngModel)]="session.session_category_id"
                >
                  <option value="">[Seleccionar un solicitante]</option>
                  <option value="M">Marketing</option>
                  <option value="I">Innovación</option>
                  <option value="C">Catálogo</option>
                  <option value="A">Calidad</option>
                </select>
                <small class="form-text text-danger"
                       *ngIf="session_category.invalid && session_category.touched">El solicitante es
                  obligatorio.</small>
              </div>

              <div class="form-group">
                <label>Estado</label>
                <select class="form-control"
                        required
                        #status="ngModel"
                        name="status"
                        [(ngModel)]="session.status"
                >
                  <option value="A">Activo</option>
                  <option value="I">Inactivo</option>
                </select>
                <small class="form-text text-danger"
                       *ngIf="status.invalid && status.touched">El status de la sesión es obligatorio.</small>
              </div>
              <div class="form-group">
                <label>Fecha Creación</label>
                <input type="text"
                       class="form-control"
                       placeholder="Fecha de creación"
                       disabled="disabled"
                       [(ngModel)]="session.created_dt"
                       name="createdAt">
                <small class="form-text text-muted">Este campo es autogenerado</small>
              </div>
              <div class="form-group">
                <label>Fecha Última Modificación</label>
                <input type="text"
                       class="form-control"
                       placeholder="Fecha de última modificación"
                       disabled="disabled"
                       [(ngModel)]="session.updated_dt"
                       name="updatedAt">
                <small class="form-text text-muted">Este campo es autogenerado</small>
              </div>
              <div class="form-group text-center mt-5">
                <a class="btn btn-danger w-25 mr-3" (click)="setMultiStep(1)">
                  <i class="fa fa-arrow-left"></i>
                  Atrás
                </a>
                <a class="btn btn-primary w-25" (click)="setMultiStep(3)">
                  <i class="fa fa-arrow-right"></i>
                  Siguiente
                </a>
              </div>

            </div>

            <div class="form-group " id="showStep3" [ngClass]="currentStep === 'showStep3' ? '': 'd-none'">
              <h3 *ngIf="!session.id">Seleccione los productos de la sesión</h3>
              <h3 *ngIf="session.id">Productos de la sesión</h3>
              <hr>
              <div class="row mb-2" *ngIf="session.id">
                <small *ngIf="session.products.length === 0">No hay productos.</small>
                <span class="badge badge-pill badge-info mt-2 mb-2 ml-1 animated fadeIn fast"

                      *ngFor="let product of session.products">{{product.name}}</span>
              </div>
              <div class="row mb-5" *ngIf="session.id">
                <button type="button" class="btn btn-outline-primary btn-sm" (click)="showDualList = !showDualList">
                  Editar
                  Productos
                </button>
              </div>
              <input *ngIf="!session.id || showDualList" class="form-control mr-sm-2 mb-2" type="search"
                     placeholder="Filtrar Productos" aria-label="Search"
                     (keyup)="filterProducts(productsSearch.value)" #productsSearch>
              <dual-list *ngIf="!session.id || showDualList"
                         [source]="this.products"
                         [(destination)]="selectedProducts"
                         [display]="listBoxDisplay"
                         [format]="listBoxFormat"
                         [key]="listBoxKey"
                         (destinationChange)="onDualListBoxSelectionChange()"
                         height="200px"
              ></dual-list>

              <br>
              <br>
              <br>
              <br>

              <h2>Configuración de Productos</h2>
              <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

                <ng-container matColumnDef="calibrate">
                  <th mat-header-cell *matHeaderCellDef> Calibración</th>
                  <td mat-cell *matCellDef="let row">
                    <mat-checkbox (change)="setCalibrationProduct($event, row)" [checked]="row.calibrate"></mat-checkbox>
                  </td>
                </ng-container>

                <ng-container matColumnDef="id">
                  <th mat-header-cell *matHeaderCellDef> ID</th>
                  <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                </ng-container>

                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef> Producto</th>
                  <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                </ng-container>

                <ng-container matColumnDef="description">
                  <th mat-header-cell *matHeaderCellDef> Descripción</th>
                  <td mat-cell *matCellDef="let element"> {{element.description}} </td>
                </ng-container>

                <ng-container matColumnDef="harvest_year">
                  <th mat-header-cell *matHeaderCellDef> Año de cosecha</th>
                  <td mat-cell *matCellDef="let element"> {{element.harvest_year}} </td>
                </ng-container>

                <ng-container matColumnDef="level">
                  <th mat-header-cell *matHeaderCellDef> Nivel</th>
                  <td mat-cell *matCellDef="let element"> {{element.level}} </td>
                </ng-container>

                <ng-container matColumnDef="wine_internal_tp">
                  <th mat-header-cell *matHeaderCellDef> Tipo de vino (código interno)</th>
                  <td mat-cell *matCellDef="let element"> {{element.wine_internal_tp}} </td>
                </ng-container>

                <ng-container matColumnDef="include_visualization">
                  <th mat-header-cell *matHeaderCellDef> Incluir en Visualización</th>
                  <td mat-cell *matCellDef="let element">
                    <mat-checkbox (change)="setVisibleForVisualization($event, element)" [checked]=element.include_visualization></mat-checkbox>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let element; columns: displayedColumns;">
                </tr>
              </table>


              <div class="form-group text-center mt-5">
                <a class="btn btn-danger w-25 mr-3" (click)="setMultiStep(2)">
                  <i class="fa fa-arrow-left"></i>
                  Atrás
                </a>
                <a class="btn btn-primary w-25" (click)="setMultiStep(4)">
                  <i class="fa fa-arrow-right"></i>
                  Siguiente
                </a>
              </div>
            </div>

            <div id="showStep4" [ngClass]="currentStep === 'showStep4' ? '': 'd-none'">
              <div class="row">
                <h3>Descriptores de la sesión</h3>
              </div>
              <small class="form-text text-danger"
                     *ngIf="session.product_tp_id === ''">Debe seleccionar el tipo de vino antes de ver los
                descriptores.</small>
              <hr>
              <div class="form-group animated fadeIn fast" *ngIf="showFeatures || session.id">

                <div class="row animated fadeIn fast">
        <span class="badge badge-pill m-1 animated fadeIn fast selectable"
              [ngClass]="descriptor.selected ? 'badge-success': 'badge-secondary'"
              *ngFor="let descriptor of descriptors;let i = index" (click)="changeFeatureStatus(i)">
        {{descriptor.name}}
      </span>
                </div>
              </div>

              <div class="form-group text-center mt-5">
                <a class="btn btn-danger w-25 mr-3" (click)="setMultiStep(3)">
                  <i class="fa fa-arrow-left"></i>
                  Atrás
                </a>
                <a class="btn btn-primary w-25" (click)="setMultiStep(5)">
                  <i class="fa fa-arrow-right"></i>
                  Siguiente
                </a>
              </div>
            </div>

            <div class="form-group " id="showStep5" [ngClass]="currentStep === 'showStep5' ? '': 'd-none'">
              <div class="row">
                <h3>Seleccione los panelistas</h3>
              </div>

              <hr>

              <dual-list
                [source]="panelistsGroups"
                [(destination)]="selectedPanelistsGroups"
                [display]="listBoxDisplayPanelists"
                [format]="listBoxFormat"
                [key]="listBoxKey"
                height="200px"
              ></dual-list>

              <div class="form-group text-center mt-5">
                <a class="btn btn-danger w-25 mr-3" (click)="setMultiStep(4)">
                  <i class="fa fa-arrow-left"></i>
                  Atrás
                </a>
                <a class="btn btn-primary w-25" (click)="setMultiStep(6)">
                  <i class="fa fa-arrow-right"></i>
                  Siguiente
                </a>
              </div>
            </div>

            <div id="showStep6" [ngClass]="currentStep === 'showStep6' ? '': 'd-none'">
              <h3>Resumen de Sesión</h3>
              <hr>
              <table border="1" cellpadding="5" cellspacing="0" width="600px" align="center">
                <tr>
                  <td style="font-weight: bold;">Nombre de la sesión:</td>
                  <td>{{session.name}}</td>
                </tr>
                <tr>
                  <td style="font-weight: bold;">Categoría:</td>
                  <td>
                    <p *ngIf="session.product_tp_id === 'SA'">Sin Asignar</p>
                    <p *ngIf="session.product_tp_id === 'T'">Tinto</p>
                    <p *ngIf="session.product_tp_id === 'R'">Rosado</p>
                    <p *ngIf="session.product_tp_id === 'B'">Blanco</p>
                    <p *ngIf="session.product_tp_id === 'E'">Espumante</p>
                    <p *ngIf="session.product_tp_id === 'A'">Baya</p>
                    <p *ngIf="session.product_tp_id === 'C'">Cooler</p>
                  </td>
                </tr>
                <tr>
                  <td style="font-weight: bold;">Fecha de la sesión:</td>
                  <td>{{session.session_dt | date: 'fullDate'}}</td>
                </tr>
                <tr>
                  <td style="font-weight: bold;">Tipo de la sesión:</td>
                  <td>
                    <p *ngIf="session.session_tp === 'G'">Generación de Perfil</p>
                    <p *ngIf="session.session_tp === 'C'">Comparación de Perfil</p>
                    <p *ngIf="session.session_tp === 'T'">Triangular</p>
                  </td>
                </tr>
                <tr>
                  <td style="font-weight: bold;">Solicitante:</td>
                  <td>
                    <p *ngIf="session.session_category_id === 'M'">Marketing</p>
                    <p *ngIf="session.session_category_id === 'I'">Innovación</p>
                    <p *ngIf="session.session_category_id === 'C'">Catálogo</p>
                    <p *ngIf="session.session_category_id === 'A'">Calidad</p>
                  </td>
                </tr>
                <tr>
                  <td style="font-weight: bold;">Descriptores seleccionados:</td>
                  <td>{{this.session.descriptors.length}}</td>
                </tr>
                <tr>
                  <td style="font-weight: bold;">Productos seleccionados:</td>
                  <td>{{selectedProducts.length}}</td>
                </tr>
                <tr>
                  <td style="font-weight: bold;">Producto a calibrar:</td>
                  <td>{{this.getCalibrationProduct()}}</td>
                </tr>
                <tr>
                  <td style="font-weight: bold;">País seleccionado:</td>
                  <td>{{selectedCountry}}</td>
                </tr>
              </table>
              <br>
              <br>

              <div class="form-group text-center">
                <a class="btn btn-danger w-25 mr-3" (click)="setMultiStep(5)">
                  <i class="fa fa-arrow-left"></i>
                  Atrás
                </a>
                <button type="submit"
                        class="btn btn-primary w-25">
                  <i class="fa fa-save"></i>
                  Guardar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
