import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import {registerLocaleData} from '@angular/common';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {NavbarComponent} from './components/shared/navbar/navbar.component';
import {LoginComponent} from './components/login/login.component';
import {PasswordComponent} from './components/login/password.component';
import {HomeComponent} from './components/home/home.component';
import {SessionsComponent} from './components/sessions/sessions.component';
import {PanelistsComponent} from './components/panelists/panelists.component';
import {FeaturesComponent} from './components/maintainers/features-maintainer/features.component';
import {FeatureMantComponent} from './components/maintainers/feature-maintainer/feature-mant.component';
import {FeaturesOrderComponent} from './components/maintainers/feature-order-maintainer/feature-order-mant.component';
import {UsersComponent} from './components/maintainers/users-maintainer/users.component';
import {UserMantComponent} from './components/maintainers/user-maintainer/user-mant.component';
import {LocalizationComponent} from './components/maintainers/localization-maintainer/localization.component';

import localeEsCL from '@angular/common/locales/es-CL';
import {FormsModule} from '@angular/forms';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {AuthInterceptorService} from './services/auth-interceptor.service';
import {ProductMantainerComponent} from './components/maintainers/product-maintainer/product-mantainer.component';
import {ProductsMantainerComponent} from './components/maintainers/products-maintainer/products-mantainer.component';
import {SessionComponent} from './components/sessions/session/session.component';
import {AngularDualListBoxModule} from 'angular-dual-listbox';
import {PanelistgroupComponent} from './components/panelists/panelistgroup/panelistgroup.component';
import {EvaluateComponent} from './components/sessions/evaluate/evaluate.component';
import {MatTableModule} from "@angular/material/table";
import {MatCheckboxModule} from "@angular/material/checkbox";
import { ReportsComponent } from './components/sessions/reports/reports.component';


registerLocaleData(localeEsCL, 'es-CL');

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    LoginComponent,
    PasswordComponent,
    HomeComponent,
    SessionsComponent,
    PanelistsComponent,
    FeaturesComponent,
    FeaturesOrderComponent,
    FeatureMantComponent,
    ProductMantainerComponent,
    ProductsMantainerComponent,
    SessionComponent,
    UsersComponent,
    UserMantComponent,
    PanelistgroupComponent,
    EvaluateComponent,
    LocalizationComponent,
    ReportsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    AngularDualListBoxModule,
    MatTableModule,
    MatCheckboxModule,

  ],
  providers: [{
    provide: LOCALE_ID, useValue: 'es-CL'
  }, {
    provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule {
}
