import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {SessionModel} from '../models/session.model';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import Swal from "sweetalert2";

@Injectable({
  providedIn: 'root'
})
export class SessionsService {

  private URL_BASE = `${environment.API_URL}/api/session`;

  constructor(private http: HttpClient) {
  }


  createSession(session: SessionModel): Observable<any> {
    return this.http.post(`${this.URL_BASE}`, session)
      .pipe(
        map((response: any) => {
            session.id = response.id;
            session.created_dt = response.created_dt;
            session.updated_dt = response.updated_dt;
            return session;
          }
        )
      );
  }

  updateSession(session: SessionModel): Observable<any> {
    return this.http.put(`${this.URL_BASE}/${session.id}`, session, {observe: 'response'});
  }


  getSessions(limit?: number, offset?: number): Observable<any> {
    const params = new HttpParams()
    .set('limit', String(limit))
    .set('offset', String(offset));
    return this.http.get(this.URL_BASE, {params});
  }

  getSession(id: string, analysis?: string): Observable<any> {
    if (analysis !== undefined && analysis !== null) {
      const params = new HttpParams()
        .set('analysis', 'chemical');
      return this.http.get(`${this.URL_BASE}/${id}`, {params});
    }
    return this.http.get(`${this.URL_BASE}/${id}`);
  }

  deleteSession(id: string): Observable<any> {
    return this.http.delete(`${this.URL_BASE}/${id}`, {observe: 'response'});
  }

  searchSession(searchTerm: string, sessions: SessionModel[]): SessionModel[] {
    const sessionsArr: SessionModel[] = [];
    searchTerm = searchTerm.toLowerCase();
    for (const session of sessions) {
      const name = session.name.toLowerCase();
      if (name.indexOf(searchTerm) >= 0 || session.id.toString().indexOf(searchTerm) >= 0) {
        sessionsArr.push(session);
      }
    }
    return sessionsArr;
  }

  uploadSpreadsheet(file): Observable<any> {
    const baseApiUrl = `${environment.API_URL}/api/evaluation/evaluations/load_session_spreadsheet/`;
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post(baseApiUrl, formData);
  }

  downloadSpreadsheet(session_id: string, token): Observable<any> {
    const url = `${environment.API_URL}/api/evaluation/evaluations/get_session_spreadsheet/`;
    return this.http.get(`${url}?session_id=${session_id}`, {headers: token});
  }

  setSessionAnalysis(payload: object): void {
    Swal.fire({
      icon: 'info',
      title: 'Guardando información!',
      text: 'Espere...',
      allowOutsideClick: false
    });
    Swal.showLoading();

    this.http.post(`${this.URL_BASE}/analysis`, payload)
      .pipe(
        map((response: any) => {
          return response;
        })
      )
      .subscribe(
        (data) => {
          // Handle successful response here
          Swal.fire({
            icon: 'success',
            title: 'Análisis Químico',
            text: 'Se guardó correctamente'
          });
        },
        (error) => {
          // Handle errors here
          Swal.fire({
            icon: 'error',
            title: 'Análisis Químico',
            text: 'Ocurrió un error al guardar los datos'
          });
        }
      );
  }
}
