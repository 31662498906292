<app-navbar></app-navbar>

<div class="d-flex justify-content-center align-items-center mx-5">
  <div class="col">
    <h1 style="margin-left: 100px; margin-top: 40px"><b>Análisis Químico:</b> {{ this.session_name }}</h1>

    <div class="text-right mt-5">
      <button class="btn btn-danger" routerLink="/sesiones">
        <i class="fa fa-arrow-left"></i>
        Volver
      </button>
    </div>

    <hr class="mt-5">

    <div class="row">
      <div class="col">
        <div class="alert alert-info text-center mt-3 animated fadeIn fast" *ngIf="loading">
          <h4 class="alert-heading">Cargando...</h4><p><i class="fa fa-sync-alt fa-spin fa-2x"></i></p>
          <p class="mb-0">Espere por favor</p>
        </div>
        <table class="table">
          <thead class="thead-dark">
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Nombre</th>
            <th scope="col">Descripción Interna</th>
            <th scope="col">Cosecha</th>
            <th scope="col">Índice Color</th>
            <th scope="col">Ácido Málico [g/L]</th>
            <th scope="col">ALC° [%]</th>
            <th scope="col">AT [g/L]</th>
            <th scope="col">pH</th>
            <th scope="col">AV [g/L]</th>
            <th scope="col">AR [g/L]</th>
            <th scope="col">SO2L [mg/L]</th>
            <th scope="col">SO2T [mg/L]</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let product of this.products; index as i">
            <td contenteditable="false" scope="row" class="animated fadeIn" id="feature{{product['product_id']}}">{{ product["product_id"] }}</td>
            <td contenteditable="false">{{ product["name"] }}</td>
            <td contenteditable="false">{{ product["description"] }}</td>
            <td contenteditable="false">{{ product["harvest_year"] }}</td>
            <td
              *ngIf="product['product_type'] === 'T'" contenteditable="true"
              style="background-color: rgba(244,205,205,0.56)"
              (input)="onInput(product['product_id'], 'color_index', $event.target['innerText'])"
            > {{product['color_index']}}
            </td>
            <td
              *ngIf="product['product_type'] != 'T'" contenteditable="false"
              style="background-color: rgba(244,205,205,0.56)"
              (input)="onInput(product['product_id'], 'color_index', $event.target['innerText'])"
            > 0
            </td>
            <td
              *ngIf="product['product_type'] === 'B'" contenteditable="true"
              style="background-color: rgba(244,205,205,0.56)"
              (input)="onInput(product['product_id'], 'acid_mal', $event.target['innerText'])"
            > {{product['acid_mal']}}
            </td>
            <td
              *ngIf="product['product_type'] != 'B'" contenteditable="false"
              style="background-color: rgba(244,205,205,0.56)"
              (input)="onInput(product['product_id'], 'acid_mal', $event.target['innerText'])"
            > 0
            </td>
            <td
              contenteditable="true"
              style="background-color: rgba(244,205,205,0.56)"
              (input)="onInput(product['product_id'], 'alc', $event.target['innerText'])"
            > {{ product['alc'] }}
            </td>
            <td
              contenteditable="true"
              style="background-color: rgba(244,205,205,0.56)"
              (input)="onInput(product['product_id'], 'at', $event.target['innerText'])"
            > {{ product['at'] }}
            </td>
            <td
              contenteditable="true"
              style="background-color: rgba(244,205,205,0.56)"
              (input)="onInput(product['product_id'], 'ph', $event.target['innerText'])"
            > {{ product['ph'] }}
            </td>
            <td
              contenteditable="true"
              style="background-color: rgba(244,205,205,0.56)"
              (input)="onInput(product['product_id'], 'av', $event.target['innerText'])"
            > {{ product['av'] }}
            </td>
            <td
              contenteditable="true"
              style="background-color: rgba(244,205,205,0.56)"
              (input)="onInput(product['product_id'], 'ar', $event.target['innerText'])"
            > {{ product['ar'] }}
            </td>
            <td
              contenteditable="true"
              style="background-color: rgba(244,205,205,0.56)"
              (input)="onInput(product['product_id'], 'so2l', $event.target['innerText'])"
            > {{ product['so2l'] }}
            </td>
            <td
              contenteditable="true"
              style="background-color: rgba(244,205,205,0.56)"
              (input)="onInput(product['product_id'], 'so2t', $event.target['innerText'])"
            > {{ product['so2t'] }}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <br>
    <br>

    <div class="form-group text-center">
      <button (click)="saveReport()" id="save-button" type="submit" class="btn btn-primary"><i class="fa fa-save"></i> Guardar</button>
    </div>

  </div>
</div>
