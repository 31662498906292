import {Injectable} from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {LoginService} from './login.service';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

  constructor(private router: Router, public loginService: LoginService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token: string = this.loginService.getToken();
    let request = req;

    if (token) {
      this.loginService.authenticated = true;
      request = req.clone({
        setHeaders: {
          authorization: `Token ${token}`
        }
      });
    }

    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {},
        (response: any) => {
          if (response instanceof HttpErrorResponse) {
            if (response.status === 401) {
              this.router.navigateByUrl('/login');

              Swal.fire({
                icon: 'warning',
                title: 'No Permitido',
                text: response.status + ' - ' + response.statusText
              });
            } else if (response.status === 403) {
              this.router.navigateByUrl('/inicio');

              Swal.fire({
                icon: 'warning',
                title: 'No Permitido',
                text: response.error.message
              });
            }
          }
        }
      )
    );
  }


}
